import React, { useEffect, useState } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { onAuthStateChanged, User } from 'firebase/auth';
import { auth } from './firebaseConfig';
import { ConsolePage } from './pages/ConsolePage';
import LandingPage from './pages/LandingPage';
import Layout from './components/Layout';
import MenuLayout from './components/MenuLayout';
import AssistantList from './pages/AssistantList';
import Login from './components/Login';
import { RolePlayScore } from './pages/RolePlayScore';
import TrainingPlans from './pages/TrainingPlans';
import SessionsList from './pages/SessionsList';
import Resume from './pages/Resume';
import CoachesList from './components/CoachesList';
import CreateEditCoach from './components/CreateEditCoach';
import ConfigurationPage from './pages/ConfigurationPage';

function App() {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return null;
  }

  const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
    if (!user) {
      return <Navigate to="/login" />;
    }
    return (
      <Layout user={user}>
        <MenuLayout user={user}>
          {children}
        </MenuLayout>
      </Layout>
    );
  };

  return (
    <ChakraProvider>
      <Router>
        <Routes>
          <Route path="/" element={user ? <Navigate to="/resume" /> : <LandingPage />} />
          
          <Route path="/login" element={<Layout user={user}><Login /></Layout>} />
          
          <Route path="/resume" element={
            <ProtectedRoute>
              <Resume user={user} />
            </ProtectedRoute>
          } />
          
          <Route path="/assistants" element={
            <ProtectedRoute>
              <CoachesList language="english" />
            </ProtectedRoute>
          } />
          
          <Route path="/sessions" element={
            <ProtectedRoute>
              <SessionsList />
            </ProtectedRoute>
          } />

          <Route path="/training" element={
            <ProtectedRoute>
              <TrainingPlans />
            </ProtectedRoute>
          } />

          <Route path="/config" element={
            <ProtectedRoute>
              <ConfigurationPage />
            </ProtectedRoute>
          } />
          
          <Route path="/roleplay/:id" element={
            <ProtectedRoute>
              <ConsolePage user={user} />
            </ProtectedRoute>
          } />
          
          <Route path="/roleplay/:sessionId/score" element={
            <ProtectedRoute>
              <RolePlayScore />
            </ProtectedRoute>
          } />
     
          <Route path="/create-coach" element={<ProtectedRoute><CreateEditCoach /></ProtectedRoute>} />
          <Route path="/edit-coach/:id" element={<ProtectedRoute><CreateEditCoach /></ProtectedRoute  >} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;