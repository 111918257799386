/**
 * Running a local relay server will allow you to hide your API key
 * and run custom logic on the server
 *
 * Set the local relay server address to:
 * REACT_APP_LOCAL_RELAY_SERVER_URL=http://localhost:8081
 *
 * This will also require you to set OPENAI_API_KEY= in a `.env` file
 * You can run it with `npm run relay`, in parallel with `npm start`
 */
const LOCAL_RELAY_SERVER_URL: string =
  process.env.REACT_APP_LOCAL_RELAY_SERVER_URL || '';

import { useEffect, useRef, useCallback, useState, ChangeEvent } from 'react';


import { RealtimeClient } from '@openai/realtime-api-beta';
import { ItemType } from '@openai/realtime-api-beta/dist/lib/client.js';
import { WavRecorder, WavStreamPlayer } from '../lib/wavtools/index.js';
import { instructions as defaultInstructions } from '../utils/conversation_config.js';
import { WavRenderer } from '../utils/wav_renderer';

import {
  Box,
  Button,
  Flex,
  IconButton,
  Text,
  VStack,
  HStack,
  Switch,
  Image,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { FaTimes, FaBolt, FaPhone } from 'react-icons/fa';

import { User } from 'firebase/auth';


import { useParams, useSearchParams, useNavigate } from 'react-router-dom'; // Assuming you're using react-router for URL params
import { getSessionDoc, getCoachDoc, getApiKeyByDomain, saveMessageToSession, updateSessionStatus, checkCompanyData } from '../utils/firebase.utils'; 

/**
 * Type for result from get_weather() function call
 */
interface Coordinates {
  lat: number;
  lng: number;
  location?: string;
  temperature?: {
    value: number;
    units: string;
  };
  wind_speed?: {
    value: number;
    units: string;
  };
}

/**
 * Type for all event logs
 */
interface RealtimeEvent {
  time: string;
  source: 'client' | 'server';
  count?: number;
  event: { [key: string]: any };
}

interface ConsolePageProps {
  user: User | null;
}

interface CoachData {
  instructions?: string;
  voice?: 'echo' | 'alloy' | 'shimmer';
  transcriptionModel?: 'whisper-1';
  assistantDescription?: string;
  assistantName?: string;
  callDescription?: string;
  rolePlayingTitle?: string;
  coldCallingDescription?: string;
  language?: string;
  callType?: string;
  imageUrl?: string;
}

interface SessionData {
  id?: string;
  coachId?: string;
  status?: string;
  createdAt?: Date;
  apiKey?: string;
  account_id?: string;
  [key: string]: any; // For any additional fields
}

// First, define the Instructions interface
interface Instructions {
  instructions?: string;  // Add optional instructions field
  productName: string;
  problem: string;
  solution: string;
  competitors: string;
  callType: string;
  rejectionsBeforeSuccess: number;
  objections: string;
  skills: string[];
}

// Define supported languages as a const array
const SUPPORTED_LANGUAGES = ['english', 'spanish', 'portuguese'] as const;
type SupportedLanguage = typeof SUPPORTED_LANGUAGES[number];

// Define the template structure
interface TemplateStructure {
  title: string;
  roleSetup: string;
  endInstructions: string;
}

// Define the templates type using Record instead of mapped type
type Templates = Record<SupportedLanguage, TemplateStructure>;

export function ConsolePage({ user }: ConsolePageProps) {

  
  /**
   * Ask user for API Key
   * If we're using the local relay server, we don't need this
   */
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const coachID = searchParams.get('coachId');

  const [apiKey, setApiKey] = useState<string | null>(null);
  const clientRef = useRef<RealtimeClient | null>(null);

  const [instructions, setInstructions] = useState(defaultInstructions);
  const [voice, setVoice] = useState('echo');
  const [transcriptionModel, setTranscriptionModel] = useState('whisper-1');
  const [coachData, setCoachData] = useState<CoachData | null>(null);

  const navigate = useNavigate();
  const toast = useToast();

  const [sessionData, setSessionData] = useState<SessionData | null>(null);

  useEffect(() => {
    if (apiKey) {
      // Initialize client when API key is available
      clientRef.current = new RealtimeClient(
        LOCAL_RELAY_SERVER_URL
          ? { url: LOCAL_RELAY_SERVER_URL }
          : {
              apiKey: apiKey,
              dangerouslyAllowAPIKeyInBrowser: true,
            }
      );

      // Set up client configuration immediately after initialization
      const client = clientRef.current;
      const wavStreamPlayer = wavStreamPlayerRef.current;

      // Set instructions and transcription
      console.log('updating instructions', instructions);
      client.updateSession({ instructions: instructions, voice: voice as 'echo' | 'alloy' | 'shimmer' | undefined });
      client.updateSession({ input_audio_transcription: { model: transcriptionModel as 'whisper-1' } });

      // handle realtime events from client + server for event logging
      client.on('realtime.event', (realtimeEvent: RealtimeEvent) => {
        setRealtimeEvents((realtimeEvents) => {
          const lastEvent = realtimeEvents[realtimeEvents.length - 1];
          if (lastEvent?.event.type === realtimeEvent.event.type) {
            // if we receive multiple events in a row, aggregate them for display purposes
            lastEvent.count = (lastEvent.count || 0) + 1;
            return realtimeEvents.slice(0, -1).concat(lastEvent);
          } else {
            return realtimeEvents.concat(realtimeEvent);
          }
        });
      });
      client.on('error', (event: any) => console.error(event));
      client.on('conversation.interrupted', async () => {
        const trackSampleOffset = await wavStreamPlayer.interrupt();
        if (trackSampleOffset?.trackId) {
          const { trackId, offset } = trackSampleOffset;
          await client.cancelResponse(trackId, offset);
        }
      });
      client.on('conversation.updated', async ({ item, delta }: any) => {
        const items = client.conversation.getItems();
        if (delta?.audio) {
          wavStreamPlayer.add16BitPCM(delta.audio, item.id);
        }
        if (item.status === 'completed' && item.formatted.audio?.length) {
          const wavFile = await WavRecorder.decode(
            item.formatted.audio,
            24000,
            24000
          );
          item.formatted.file = wavFile;
        }
        
        // Save message to Firestore
        if (item.status === 'completed' && id) {
          const messageData = {
            role: item.role,
            type: item.type,
            content: {
              text: item.formatted.text || item.formatted.transcript,
              audio: item.formatted.audio ? true : false,
              tool: item.formatted.tool || null
            },
            itemId: item.id,
            status: item.status
          };
          if( item.formatted.text || item.formatted.transcript){
            await saveMessageToSession(id, messageData);
          }
        }
        
        setItems(items);
      });

      setItems(client.conversation.getItems());

      return () => {
        // cleanup
        client.reset();
      };
    }
  }, [apiKey, instructions, voice, transcriptionModel]);

  useEffect(() => {
    const fetchSessionAndCoachData = async () => {
      if (!id || !user) {
        console.log('Missing required data:', { id, user });
        return;
      }

      try {
        // First get the API key if needed
        const email = user.email;
        const domain = email ? email.split('@')[1] : null;
        let apiKeyData = null;
        let account_id = null;

        if (domain) {
          try {
            apiKeyData = await getApiKeyByDomain(domain);
            const { companyData } = await checkCompanyData(domain);
            account_id = companyData?.account_id;
            if (apiKeyData) {
              setApiKey(apiKeyData);
            }
          } catch (error) {
            console.error('Error fetching API key:', error);
          }
        }

        // Get or create session
        let sessionData = await getSessionDoc(id);
        
        // If session doesn't exist, create it with initial data
        if (!sessionData && coachID) {
          const email = user.email;
          const domain = email?.split('@')[1];
          
          try {
            // First get the account data
            const { companyData } = await checkCompanyData(domain);
            
            if (!domain || !companyData?.account_id) {
              throw new Error('Missing required domain or account_id');
            }

            const initialData = {
              coachId: coachID,
              status: 'active',
              domain: domain,                // Required by rules
              account_id: companyData.account_id, // Required by rules
              userId: user.uid,
              userEmail: user.email,
              createdAt: new Date(),
              updatedAt: new Date()
            };

            console.log('Creating session with data:', initialData);
            sessionData = await getSessionDoc(id, initialData);
            console.log('Created new session:', sessionData);
          } catch (error) {
            console.error('Error creating session:', error);
            throw error;
          }
        }

        if (!sessionData) {
          throw new Error('Failed to get or create session');
        }

        setSessionData(sessionData);

        // Fetch coach data if we have a coachID
        if (coachID) {
          try {
            const coachData = await getCoachDoc(coachID);
            console.log('Fetched coach data:', coachData); // Debug log
            if (coachData) {
              const generatedInstructions = generateInstructions({
                productName: coachData.productName || 'Default Product',
                problem: coachData.problem || 'Default Problem',
                solution: coachData.solution || 'Default Solution',
                competitors: coachData.competitors || 'None',
                callType: coachData.callType || 'cold_call',
                rejectionsBeforeSuccess: coachData.rejectionsBeforeSuccess || 3,
                objections: coachData.objections || '',
                skills: coachData.skills || []
              }, coachData.language || 'english', coachData);

              setInstructions(generatedInstructions);
              setVoice(coachData.voice || 'echo');
              setTranscriptionModel(coachData.transcriptionModel || 'whisper-1');
              console.log('Setting coach data:', {
                ...coachData,
                callType: coachData.callType // Explicitly include callType
              }); // Debug log
              setCoachData({
                ...coachData,
                callType: coachData.callType, // Explicitly include callType
                instructions: coachData.instructions,
                voice: coachData.voice || 'echo',
                transcriptionModel: coachData.transcriptionModel || 'whisper-1',
                assistantDescription: coachData.assistantDescription,
                assistantName: coachData.assistantName,
                callDescription: coachData.callDescription,
                rolePlayingTitle: coachData.rolePlayingTitle,
                coldCallingDescription: coachData.coldCallingDescription,
                language: coachData.language,
                imageUrl: coachData.imageUrl
              });
            }
          } catch (error) {
            console.error('Error fetching coach data:', error);
            throw new Error('Failed to fetch coach data');
          }
        }

      } catch (error) {
        console.error('Error in fetchSessionAndCoachData:', error);
        toast({
          title: 'Error',
          description: error instanceof Error ? error.message : 'Failed to load session',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    };

    fetchSessionAndCoachData();
  }, [id, coachID, user, toast]);





  /**
   * Instantiate:
   * - WavRecorder (speech input)
   * - WavStreamPlayer (speech output)
   * - RealtimeClient (API client)
   */
  const wavRecorderRef = useRef<WavRecorder>(
    new WavRecorder({ sampleRate: 24000 })
  );
  const wavStreamPlayerRef = useRef<WavStreamPlayer>(
    new WavStreamPlayer({ sampleRate: 24000 })
  );

  /**
   * References for
   * - Rendering audio visualization (canvas)
   * - Autoscrolling event logs
   * - Timing delta for event log displays
   */
  const clientCanvasRef = useRef<HTMLCanvasElement>(null);
  const serverCanvasRef = useRef<HTMLCanvasElement>(null);
  const eventsScrollHeightRef = useRef(0);
  const eventsScrollRef = useRef<HTMLDivElement>(null);
  const startTimeRef = useRef<string>(new Date().toISOString());

  /**
   * All of our variables for displaying application state
   * - items are all conversation items (dialog)
   * - realtimeEvents are event logs, which can be expanded
   * - memoryKv is for set_memory() function
   * - coords, marker are for get_weather() function
   */
  const [items, setItems] = useState<ItemType[]>([]);
  const [realtimeEvents, setRealtimeEvents] = useState<RealtimeEvent[]>([]);
  const [expandedEvents, setExpandedEvents] = useState<{
    [key: string]: boolean;
  }>({});
  const [isConnected, setIsConnected] = useState(false);
  const [canPushToTalk, setCanPushToTalk] = useState(true);
  const [isRecording, setIsRecording] = useState(false);
  const [memoryKv, setMemoryKv] = useState<{ [key: string]: any }>({});
  const [isAutoTalk, setIsAutoTalk] = useState(false);

  const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    setIsAutoTalk(newValue);
    changeTurnEndType(newValue ? 'server_vad' : 'none');
  };

  const [coords, setCoords] = useState<Coordinates | null>({
    lat: 37.775593,
    lng: -122.418137,
  });
  const [marker, setMarker] = useState<Coordinates | null>(null);

  /**
   * Utility for formatting the timing of logs
   */
  const formatTime = useCallback((timestamp: string) => {
    const startTime = startTimeRef.current;
    const t0 = new Date(startTime).valueOf();
    const t1 = new Date(timestamp).valueOf();
    const delta = t1 - t0;
    const hs = Math.floor(delta / 10) % 100;
    const s = Math.floor(delta / 1000) % 60;
    const m = Math.floor(delta / 60_000) % 60;
    const pad = (n: number) => {
      let s = n + '';
      while (s.length < 2) {
        s = '0' + s;
      }
      return s;
    };
    return `${pad(m)}:${pad(s)}.${pad(hs)}`;
  }, []);

  /**
   * When you click the API key
   */
  const resetAPIKey = useCallback(() => {
    const apiKey = prompt('OpenAI API Key');
    if (apiKey !== null) {
      localStorage.clear();
      localStorage.setItem('tmp::voice_api_key', apiKey);
      window.location.reload();
    }
  }, []);

  // Move coach data fetching to a separate function
  const fetchCoachData = useCallback(async () => {
    if (!coachID || !user) {
      console.log('Missing required data:', { coachID, user });
      return null;
    }

    try {
      const coachData = await getCoachDoc(coachID);
      console.log('Raw coach data from Firestore:', coachData);
      
      if (coachData) {
        const generatedInstructions = generateInstructions({
          productName: coachData.productName || 'Default Product',
          problem: coachData.problem || 'Default Problem',
          solution: coachData.solution || 'Default Solution',
          competitors: coachData.competitors || 'None',
          callType: coachData.callType || 'cold_call',
          rejectionsBeforeSuccess: coachData.rejectionsBeforeSuccess || 3,
          objections: coachData.objections || '',
          skills: coachData.skills || []
        }, coachData.language || 'english', coachData);

        setInstructions(generatedInstructions);
        setVoice(coachData.voice || 'echo');
        setTranscriptionModel(coachData.transcriptionModel || 'whisper-1');
        
        const coachDataToSet = {
          ...coachData,
          callType: coachData.callType || 'cold_call',
          instructions: coachData.instructions,
          voice: coachData.voice || 'echo',
          transcriptionModel: coachData.transcriptionModel || 'whisper-1',
          assistantDescription: coachData.assistantDescription,
          assistantName: coachData.assistantName,
          callDescription: coachData.callDescription,
          rolePlayingTitle: coachData.rolePlayingTitle,
          coldCallingDescription: coachData.coldCallingDescription,
          language: coachData.language,
          imageUrl: coachData.imageUrl
        };
        
        console.log('Coach data being set:', coachDataToSet);
        setCoachData(coachDataToSet);
        return coachDataToSet;
      }
    } catch (error) {
      console.error('Error fetching coach data:', error);
      toast({
        title: 'Error',
        description: 'Failed to load coach data',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    return null;
  }, [coachID, user, toast]);

  // Load coach data when component mounts
  useEffect(() => {
    fetchCoachData();
  }, [fetchCoachData]);

  const connectConversation = useCallback(async () => {
    const client = clientRef.current;
    if (!client) return;

    // Check if we have coach data or instructions 
    const currentCoachData = coachData || await fetchCoachData();
    if (!currentCoachData?.instructions && (!currentCoachData || !currentCoachData.callType)) {
      toast({
        title: 'Cannot start call',
        description: 'Failed to load call configuration',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;

    // Set state variables
    startTimeRef.current = new Date().toISOString();
    setIsConnected(true);
    setRealtimeEvents([]);
    setItems(client.conversation.getItems());

    // Connect to microphone
    await wavRecorder.begin();

    // Connect to audio output
    await wavStreamPlayer.connect();

    // Connect to realtime API
    await client.connect();

    // Send initial message after connection is established
    try {
      console.log('Starting conversation with call type:', currentCoachData.callType);
      console.log('Current coach data:', currentCoachData);

      if(currentCoachData.language === 'spanish'){
        await client.sendUserMessageContent([
          {
            type: 'input_text',
            text: `Ahora comenzará el juego de roles. Como bot, debes actuar como Juan, el cliente potencial que acaba de recibir una llamada en su teléfono privado. ${
              currentCoachData.callType === 'cold_call' || currentCoachData.callType === 'cold-call' || currentCoachData.callType === 'coldCall' || currentCoachData.callType === 'cold_calling'
                ? 'No esperabas esta llamada y no conoces al vendedor ni su empresa.'
                : currentCoachData.callType === 'warm_call' || currentCoachData.callType === 'warm-call' || currentCoachData.callType === 'warmCall'
                ? 'Ya has mostrado cierto interés previo en el producto/servicio a través de una visita al sitio web o una consulta anterior.'
                : currentCoachData.callType === 'follow_up' || currentCoachData.callType === 'follow-up' || currentCoachData.callType === 'followUp'
                ? 'Has tenido una interacción previa con esta empresa y estás esperando su seguimiento.'
                : currentCoachData.callType === 'demo_call' || currentCoachData.callType === 'demo-call' || currentCoachData.callType === 'demoCall'
                ? 'Has acordado esta llamada para una demostración del producto.'
                : 'No esperabas esta llamada y no conoces al vendedor ni su empresa.'
            } Debes responder como si acabaras de contestar tu teléfono y siempre en español. Recuerda: eres el CLIENTE, no el vendedor. Tu primera respuesta debe ser "¿Hola?"`,
          },
        ]);
      } else {
        await client.sendUserMessageContent([
          {
            type: 'input_text',
            text: `The role-playing game will now begin. As the bot, you must act as John, the potential customer who just received a call on their private phone. ${
              currentCoachData.callType === 'cold_call' || currentCoachData.callType === 'cold-call' || currentCoachData.callType === 'coldCall' || currentCoachData.callType === 'cold_calling'
                ? 'You were not expecting this call and do not know the salesperson or their company.'
                : currentCoachData.callType === 'warm_call' || currentCoachData.callType === 'warm-call' || currentCoachData.callType === 'warmCall'
                ? 'You have shown prior interest in the product/service through a website visit or previous inquiry.'
                : currentCoachData.callType === 'follow_up' || currentCoachData.callType === 'follow-up' || currentCoachData.callType === 'followUp'
                ? 'You have had a previous interaction with this company and are expecting their follow-up.'
                : currentCoachData.callType === 'demo_call' || currentCoachData.callType === 'demo-call' || currentCoachData.callType === 'demoCall'
                ? 'You have agreed to this call for a product demonstration.'
                : 'You were not expecting this call and do not know the salesperson or their company.'
            } You must respond as if you just answered your phone and always in English. Remember: you are the CUSTOMER, not the salesperson. Your first response should be "Hello?"`,
          },
        ]);
      }
      console.log('Initial message sent successfully');
    } catch (error) {
      console.error('Error sending initial message:', error);
      toast({
        title: 'Error',
        description: 'Failed to initialize conversation',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  }, [coachData, fetchCoachData, toast]);

  /**
   * Disconnect and reset conversation state
   */
  const disconnectConversation = useCallback(async () => {
    const client = clientRef.current;
    setIsConnected(false);
    setRealtimeEvents([]);
    setItems([]);
    setMemoryKv({});
    setCoords({
      lat: 37.775593,
      lng: -122.418137,
    });
    setMarker(null);

    if (client) {
      client.disconnect();
    }

    const wavRecorder = wavRecorderRef.current;
    await wavRecorder.end();

    const wavStreamPlayer = wavStreamPlayerRef.current;
    await wavStreamPlayer.interrupt();

    // Update session status in Firestore and navigate to score page
    if (id) {
      console.log('disconecting conversation', id);
      try {
        await updateSessionStatus(id, 'finished');
        navigate(`/roleplay/${id}/score`);
      } catch (error) {
        console.error('Error updating session:', error);
      }
    }
  }, [id, navigate]);

  const deleteConversationItem = useCallback(async (id: string) => {
    const client = clientRef.current;
    if(!client) return;
    client.deleteItem(id);
  }, []);

  /**
   * In push-to-talk mode, start recording
   * .appendInputAudio() for each sample
   */
  const startRecording = async () => {
    const client = clientRef.current;
    if(!client) return;
    setIsRecording(true);
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const trackSampleOffset = await wavStreamPlayer.interrupt();
    if (trackSampleOffset?.trackId) {
      const { trackId, offset } = trackSampleOffset;
      await client.cancelResponse(trackId, offset);
    }
    await wavRecorder.record((data) => client.appendInputAudio(data.mono));
  };

  /**
   * In push-to-talk mode, stop recording
   */
  const stopRecording = async () => {
    const client = clientRef.current;
    if(!client) return;
    setIsRecording(false);
    const wavRecorder = wavRecorderRef.current;
    await wavRecorder.pause();
    client.createResponse();
  };

  /**
   * Switch between Manual <> VAD mode for communication
   */
  const changeTurnEndType = async (value: string) => {
    const client = clientRef.current;
    if(!client) return;
    const wavRecorder = wavRecorderRef.current;
    if (value === 'none' && wavRecorder.getStatus() === 'recording') {
      await wavRecorder.pause();
    }
    client.updateSession({
      turn_detection: value === 'none' ? null : { type: 'server_vad' },
    });
    if (value === 'server_vad' && client.isConnected()) {
      await wavRecorder.record((data) => client.appendInputAudio(data.mono));
    }
    setCanPushToTalk(value === 'none');
  };

  /**
   * Auto-scroll the event logs
   */
  useEffect(() => {
    if (eventsScrollRef.current) {
      const eventsEl = eventsScrollRef.current;
      const scrollHeight = eventsEl.scrollHeight;
      // Only scroll if height has just changed
      if (scrollHeight !== eventsScrollHeightRef.current) {
        eventsEl.scrollTop = scrollHeight;
        eventsScrollHeightRef.current = scrollHeight;
      }
    }
  }, [realtimeEvents]);

  /**
   * Auto-scroll the conversation logs
   */
  useEffect(() => {
    const conversationEls = [].slice.call(
      document.body.querySelectorAll('[data-conversation-content]')
    );
    for (const el of conversationEls) {
      const conversationEl = el as HTMLDivElement;
      conversationEl.scrollTop = conversationEl.scrollHeight;
    }
  }, [items]);

  /**
   * Set up render loops for the visualization canvas
   */
  useEffect(() => {
    let isLoaded = true;

    const wavRecorder = wavRecorderRef.current;
    const clientCanvas = clientCanvasRef.current;
    let clientCtx: CanvasRenderingContext2D | null = null;

    const wavStreamPlayer = wavStreamPlayerRef.current;
    const serverCanvas = serverCanvasRef.current;
    let serverCtx: CanvasRenderingContext2D | null = null;

    const render = () => {
      if (isLoaded) {
        if (clientCanvas) {
          if (!clientCanvas.width || !clientCanvas.height) {
            clientCanvas.width = clientCanvas.offsetWidth;
            clientCanvas.height = clientCanvas.offsetHeight;
          }
          clientCtx = clientCtx || clientCanvas.getContext('2d');
          if (clientCtx) {
            clientCtx.clearRect(0, 0, clientCanvas.width, clientCanvas.height);
            const result = wavRecorder.recording
              ? wavRecorder.getFrequencies('voice')
              : { values: new Float32Array([0]) };
            WavRenderer.drawBars(
              clientCanvas,
              clientCtx,
              result.values,
              '#0099ff',
              10,
              0,
              8
            );
          }
        }
        if (serverCanvas) {
          if (!serverCanvas.width || !serverCanvas.height) {
            serverCanvas.width = serverCanvas.offsetWidth;
            serverCanvas.height = serverCanvas.offsetHeight;
          }
          serverCtx = serverCtx || serverCanvas.getContext('2d');
          if (serverCtx) {
            serverCtx.clearRect(0, 0, serverCanvas.width, serverCanvas.height);
            const result = wavStreamPlayer.analyser
              ? wavStreamPlayer.getFrequencies('voice')
              : { values: new Float32Array([0]) };
            WavRenderer.drawBars(
              serverCanvas,
              serverCtx,
              result.values,
              '#009900',
              10,
              0,
              8
            );
          }
        }
        window.requestAnimationFrame(render);
      }
    };
    render();

    return () => {
      isLoaded = false;
    };
  }, []);

  /**
   * Render the application
   */
  return (
    <Box data-component="ConsolePage" p={4}>
      {!user && !apiKey ? (
        <><Text>Invalid Session</Text></>
      ) : (
        <Flex>
          {/* Left Column for Assistant Info */}
          <VStack
            w="80%"
            minW="400px"
            maxW="800px"
            minH="400px"
            maxH="500px"
            p={4}
            spacing={4}
            borderWidth={1}
            borderRadius="md"
            boxShadow="md"
            bg={'gray.50'}
            alignItems="center"
            justifyContent="center"
          >
            {isConnected ? (
              <>
                <Text fontSize="xl" fontWeight="bold" textAlign="center">
                  {coachData?.assistantName || "Assistant Name"}
                </Text>
                <Image
                  src={coachData?.imageUrl || "/profile2.png"}
                  alt="Sales Coach"
                  borderRadius="md"
                  boxSize="150px"
                  objectFit="cover"
                  fallback={<Image src="/profile2.png" alt="Default Sales Coach" />}
                />
                <Text textAlign="center">
                  {coachData?.callDescription || (() => {
                    const callType = coachData?.callType || 'cold_call';
                    const descriptions = {
                      cold_call: "You're about to make a cold call. Your goal is to introduce yourself and your product to a potential customer who has no prior knowledge of your company. Focus on quickly establishing rapport and creating interest.",
                      warm_call: "You're about to make a warm call to a lead who has shown previous interest. They've interacted with your company before, so focus on building upon that initial interest and moving the conversation towards next steps.",
                      follow_up: "This is a follow-up call to a previous conversation. Your contact is familiar with your offering, so focus on addressing any remaining concerns and moving towards a decision.",
                      demo_call: "You're about to conduct a product demonstration call. Your prospect has agreed to learn more about your solution, so focus on highlighting relevant features and showing clear value.",
                    };
                    
                    return `${descriptions[callType as keyof typeof descriptions] || descriptions.cold_call} The AI will play the role of the customer, responding naturally to your approach and presenting realistic objections. Stay focused on your goals while practicing effective communication and sales techniques.`;
                  })()}
                </Text>
                <Button
                  colorScheme="red"
                  onClick={disconnectConversation}
                  leftIcon={<FaTimes />}
                >
                  End Call
                </Button>
              </>
            ) : (
              <>
                <Text fontSize="xl" fontWeight="bold" textAlign="center">
                  {coachData?.rolePlayingTitle || "Sales Role-Play Session"}
                </Text>
                <Text textAlign="center" px={6}>
                  {coachData?.coldCallingDescription || 
                    "Practice your sales skills in this interactive role-play session. You'll engage with an AI-powered customer to improve your communication and negotiation abilities."}
                </Text>
                <Image
                  src={coachData?.imageUrl || "/profile2.png"}
                  alt="Sales Coach"
                  borderRadius="md"
                  boxSize="150px"
                  objectFit="cover"
                  fallback={<Image src="/profile2.png" alt="Default Sales Coach" />}
                />
                <Button
                  size="lg"
                  colorScheme="green"
                  onClick={connectConversation}
                  leftIcon={<FaPhone style={{ transform: 'rotate(90deg)' }} />}
                  px={8}
                  py={6}
                  fontSize="md"
                  fontWeight="bold"
                  _hover={{ transform: 'scale(1.05)' }}
                  transition="all 0.2s"
                >
                  Start Call
                </Button>
              </>
            )}
          </VStack>

          {/* Right Column for Conversation and Actions */}
          {isConnected && (
            <VStack
              w="75%"
              minW="500px"
              maxW="800px"
              minH="400px"
              maxH="600px"
              spacing={4}
              pl={4}
              alignItems="stretch"
            >
              <Box
                flex="1"
                overflowY="auto"
                minH="400px"
                maxH="600px"
                minW="700px"
                borderWidth={1}
                borderRadius="md"
                boxShadow="md"
                p={4}
              >
                <Text fontSize="lg" mb={4}>
                  Conversation
                </Text>
                {!items.length && <Text>Awaiting connection...</Text>}
                {items.map((conversationItem, i) => (
                  <Flex
                    key={conversationItem.id}
                    justify="space-between"
                    align="center"
                    mb={2}
                  >
                    <Box>
                      <Text fontWeight="bold">
                        {(
                          conversationItem.role || conversationItem.type
                        ).replaceAll('_', ' ')}
                      </Text>
                      <Box>
                        {conversationItem.type === 'function_call_output' && (
                          <Text>{conversationItem.formatted.output}</Text>
                        )}
                        {!!conversationItem.formatted.tool && (
                          <Text>
                            {conversationItem.formatted.tool.name}(
                            {conversationItem.formatted.tool.arguments})
                          </Text>
                        )}
                        {!conversationItem.formatted.tool &&
                          conversationItem.role === 'user' && (
                            <Text>
                              {conversationItem.formatted.transcript ||
                                (conversationItem.formatted.audio?.length
                                  ? '(awaiting transcript)'
                                  : conversationItem.formatted.text ||
                                    '(item sent)')}
                            </Text>
                          )}
                        {!conversationItem.formatted.tool &&
                          conversationItem.role === 'assistant' && (
                            <Text>
                              {conversationItem.formatted.transcript ||
                                conversationItem.formatted.text ||
                                '(truncated)'}
                            </Text>
                          )}
                        {conversationItem.formatted.file && (
                          <audio
                            src={conversationItem.formatted.file.url}
                            controls
                          />
                        )}
                      </Box>
                    </Box>
                    <IconButton
                      icon={<FaTimes />}
                      onClick={() =>
                        deleteConversationItem(conversationItem.id)
                      }
                      aria-label="Delete conversation item"
                    />
                  </Flex>
                ))}
              </Box>

              {/* Footer Actions */}
              <HStack w="100%" justify="space-between" p={4}>
                <Switch
                  size="lg"
                  isChecked={isAutoTalk}
                  onChange={handleSwitchChange}
                >
                  Auto/Manual
                </Switch>
                {isConnected && canPushToTalk && (
                  <Button
                    colorScheme={isRecording ? 'red' : 'blue'}
                    onMouseDown={startRecording}
                    onMouseUp={stopRecording}
                  >
                    {isRecording ? 'Release to send' : 'Push to talk'}
                  </Button>
                )}
              </HStack>
            </VStack>
          )}
        </Flex>
      )}
    </Box>
  );
}

const generateInstructions = (config: Instructions, language: string, coachData?: CoachData | null) => {
  // If coachData has instructions, use those instead of generating or using config instructions
  if (coachData?.instructions) {
    return coachData.instructions;
  }
  
  // If config has instructions, use those
  if (config.instructions) {
    return config.instructions;
  }

  // Otherwise, generate instructions using existing template logic
  const formatObjections = (objections: string) => {
    return objections.split(',')
      .map(obj => obj.trim())
      .filter(obj => obj.length > 0)
      .map(obj => `  - ${obj}`)
      .join('\n');
  };

  const formatSkills = (skills: string[]) => {
    return skills
      .map(skill => `  - ${skill}`)
      .join('\n');
  };

  const getSkillDescriptions = (skills: string[]) => {
    const skillDescriptions = {
      rapport_building: {
        en: "Building trust and connection with potential customers",
        es: "Construir confianza y conexión con clientes potenciales",
        pt: "Construir confiança e conexão com clientes potenciais"
      },
      communication_skills: {
        en: "Clear and effective verbal communication",
        es: "Comunicación verbal clara y efectiva",
        pt: "Comunicação verbal clara e efetiva"
      },
      closing_techniques: {
        en: "Methods to guide conversations toward successful deal completion",
        es: "Métodos para guiar conversaciones hacia el cierre exitoso",
        pt: "Métodos para guiar conversas para o fechamento bem-sucedido"
      },
      objection_handling: {
        en: "Effectively addressing and overcoming customer concerns",
        es: "Abordar y superar efectivamente las preocupaciones del cliente",
        pt: "Abordar e superar efetivamente as preocupações do cliente"
      }
    };

    return skills.map(skill => {
      const desc = skillDescriptions[skill as keyof typeof skillDescriptions];
      return desc ? `  - ${skill}: ${desc[language.substring(0,2) as keyof typeof desc]}` : `  - ${skill}`;
    }).join('\n');
  };

  const validLanguage: SupportedLanguage = 
    ['english', 'spanish', 'portuguese'].includes(language.toLowerCase() as SupportedLanguage)
      ? (language.toLowerCase() as SupportedLanguage)
      : 'english';

  const templates: Templates = {
    english: {
      title: "**Sales Training Bot Instructions**",
      roleSetup: `
You will be playing the role of a potential customer interested in ${config.productName}.

**Context:**
- Product: ${config.productName}
- Problem Solved: ${config.problem}
- Solution Offered: ${config.solution}
- Main Competitors: ${config.competitors}

**Objection Handling:**
- You will present ${config.rejectionsBeforeSuccess} major objections before considering a positive outcome
- These objections should come from this pool of concerns:
${formatObjections(config.objections)}

**Evaluation Focus:**
The following skills are being evaluated:
${getSkillDescriptions(config.skills)}`,
      endInstructions: `
**Response Guidelines:**
- Maintain consistent knowledge about your role and company
- Use realistic business language and industry terminology
- Express authentic concerns based on the provided context
- Don't invent information about the product or competitors
- If asked something you don't know, naturally respond with "I'm not sure about that"`
    },
    spanish: {
      title: "**Instrucciones para Bot de Entrenamiento de Ventas**",
      roleSetup: `
**Rol y Personalidad del Bot:**
- Eres un tomador de decisiones en una empresa que podría beneficiarse de ${config.productName}
- Actualmente utilizas métodos tradicionales o soluciones de la competencia
- Debes mantener un comportamiento y conocimiento consistentes durante la conversación
- Tus respuestas y preocupaciones sobre la implementación de nuevas soluciones deben ser realistas

**Contexto de la Simulación:**
Esta es una simulación de ${config.callType.replace('_', ' ')} donde:
- Problema a resolver: ${config.problem}
- Solución actual ofrecida: ${config.solution}
- Competidores del mercado: ${config.competitors}

**Dinámica de Manejo de Objeciones:**
- Presentarás ${config.rejectionsBeforeSuccess} objeciones principales antes de considerar un resultado positivo
- Estas objeciones deben provenir de esta lista de preocupaciones:
${formatObjections(config.objections)}

**Enfoque de Evaluación:**
Se evaluarán las siguientes habilidades:
${getSkillDescriptions(config.skills)}

**Reglas de Conversación:**
1. Comienza escéptico pero mantente abierto a argumentos convincentes
2. No rechaces inmediatamente la llamada; permite que la conversación se desarrolle
3. Haz preguntas desafiantes sobre:
   - Costos y tiempo de implementación
   - Integración con sistemas existentes
   - Requisitos de capacitación
   - Justificación del ROI
   - Preocupaciones de seguridad
   - Historias de éxito de clientes anteriores
4. Muestra interés genuino si el vendedor:
   - Demuestra clara comprensión de tu industria
   - Proporciona casos de estudio relevantes
   - Aborda tus preocupaciones específicas
   - Muestra valor más allá de las características

**Pautas de Respuesta:**
- Mantén conocimiento consistente sobre tu rol y empresa
- Utiliza lenguaje empresarial realista y terminología de la industria
- Expresa preocupaciones auténticas basadas en el contexto proporcionado
- No inventes información sobre el producto o competidores
- Si te preguntan algo que no sabes, responde naturalmente con "No estoy seguro de eso"`,
      endInstructions: `
**After Call Evaluation:**
Evaluate the salesperson on:
1. How well they handled the ${config.rejectionsBeforeSuccess} objections
2. Their product knowledge and solution alignment
3. Professional communication and rapport building
4. Ability to provide relevant examples and evidence
5. Clear next steps and value proposition`
    },
    portuguese: {
      title: "**Instruções para Bot de Treinamento de Vendas**",
      roleSetup: `
**Função e Personalidade do Bot:**
- Você é um tomador de decisões em uma empresa que poderia se beneficiar de ${config.productName}
- Atualmente você usa métodos tradicionais ou soluções concorrentes
- Você deve manter comportamento e conhecimento consistentes durante a conversa
- Suas respostas e preocupações sobre a implementação de novas soluções devem ser realistas

**Contexto da Simulação:**
Esta é uma simulação de ${config.callType.replace('_', ' ')} onde:
- Problema a resolver: ${config.problem}
- Solução atual oferecida: ${config.solution}
- Concorrentes do mercado: ${config.competitors}

**Dinâmica de Tratamento de Objeções:**
- Você apresentará ${config.rejectionsBeforeSuccess} objeções principais antes de considerar um resultado positivo
- Estas objeções devem vir deste conjunto de preocupações:
${formatObjections(config.objections)}

**Foco da Avaliação:**
As seguintes habilidades estão sendo avaliadas:
${getSkillDescriptions(config.skills)}

**Regras de Conversação:**
1. Comece cético mas permaneça aberto a argumentos convincentes
2. Não rejeite imediatamente a ligação; permita que a conversa se desenvolva
3. Faça perguntas desafiadoras sobre:
   - Custos e cronograma de implementação
   - Integração com sistemas existentes
   - Requisitos de treinamento
   - Justificativa de ROI
   - Preocupações com segurança
   - Histórias de sucesso de clientes anteriores
4. Mostre interesse genuíno se o vendedor:
   - Demonstrar clara compreensão do seu setor
   - Fornecer casos de estudo relevantes
   - Abordar suas preocupações específicas
   - Mostrar valor além das características

**Diretrizes de Resposta:**
- Mantenha conhecimento consistente sobre seu papel e empresa
- Use linguagem empresarial realista e terminologia do setor
- Expresse preocupações autênticas baseadas no contexto fornecido
- Não invente informações sobre o produto ou concorrentes
- Se perguntado sobre algo que não sabe, responda naturalmente com "Não tenho certeza sobre isso"

**Critérios de Sucesso:**
- O vendedor deve abordar efetivamente ${config.rejectionsBeforeSuccess} objeções principais
- Eles devem demonstrar alinhamento da proposta de valor com suas necessidades
- Devem fornecer próximos passos claros e valor em continuar a conversa

**Fluxo da Chamada:**
1. Fase de Ceticismo Inicial
2. Fase de Coleta de Informações
3. Fase de Tratamento de Objeções (${config.rejectionsBeforeSuccess} objeções principais)
4. Fase de Proposta de Valor
5. Fase de Decisão/Próximos Passos`,

      endInstructions: `
**Avaliação Pós-Chamada:**
Avalie o vendedor em:
1. Como lidou com as ${config.rejectionsBeforeSuccess} objeções
2. Conhecimento do produto e alinhamento da solução
3. Comunicação profissional e construção de rapport
4. Capacidade de fornecer exemplos e evidências relevantes
5. Próximos passos claros e proposta de valor`
    }
  } as const;

  const template = templates[validLanguage];

  return `
${template.title}
${template.roleSetup}
${template.endInstructions}
`;
};
