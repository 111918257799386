import React from 'react';
import {
  Box,
  Flex,
  HStack,
  Image,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Avatar,
  Text,
} from '@chakra-ui/react';
import { User } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import { useNavigate } from 'react-router-dom';

interface HeaderProps {
  user: User | null;
}

const Header: React.FC<HeaderProps> = ({ user }) => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <Box h="64px" px={4}>
      <Flex h="full" align="center" justify="space-between" maxW="1400px" mx="auto">
        <HStack spacing={3} cursor="pointer" onClick={() => navigate('/')} >
          <Image
            src="/logo.png"
            alt="ObjectionGuru"
            h="40px"
          />
          <Text
            fontSize="xl"
            fontWeight="bold"
            display={{ base: 'none', md: 'block' }}
          >
            ObjectionGuru
          </Text>
        </HStack>

        {user && (
          <HStack spacing={4}>
            <Menu>
              <MenuButton
                as={Button}
                variant="ghost"
                rounded="full"
                px={2}
              >
                <Avatar size="sm" name={user.displayName || undefined} src={user.photoURL || undefined} />
              </MenuButton>
              <MenuList>
                <MenuItem onClick={handleLogout}>Sign Out</MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        )}
      </Flex>
    </Box>
  );
};

export default Header;