import React, { useState } from 'react';
import {
  Box,
  Image,
  Text,
  Button,
  Tag,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Divider,
  Container,
  HStack,
  SimpleGrid,
  useToast,
  VStack,
  Flex,
  Heading,
  Icon,
} from '@chakra-ui/react';
import { FaLightbulb, FaStopwatch, FaChartLine, FaChalkboardTeacher, FaClipboardCheck, FaCogs, FaEnvelope, FaUsers, FaUserTie, FaClock, FaCrown, FaStar } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import RolePlaySimulationCard from '../components/RolePlaySimulationCard';
import { addLead } from '../utils/firebase.utils';

const LandingPage: React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const toast = useToast();

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const disallowedDomains = ['gmail.com', 'hotmail.com', 'yahoo.com'];
    const domain = email.split('@')[1];
    return emailRegex.test(email) && !disallowedDomains.includes(domain);
  };

  const handleBuildSalesCoach = async () => {
    if (!email || !isValidEmail(email)) {
      toast({
        title: 'Invalid Email',
        description: 'Please enter a valid business email address (no Gmail, Hotmail, or Yahoo).',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const leadId = await addLead(email);
      console.log('Lead added with ID: ', leadId);
      navigate(`/login?email=${encodeURIComponent(email)}`);
    } catch (e) {
      console.error('Error adding lead: ', e);
      toast({
        title: 'Error',
        description: 'There was an error saving your email. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex direction="column" align="center" justify="center" minH="100vh" bgColor="gray.50">
      {/* Header */}
      <Flex w="full" p={5} bg="white" align="center" borderBottom="1px solid" borderColor="gray.200">
        <Container maxW="container.xl">
          <Flex justify="flex-start" align="center">
            <Image src="/logo.png" alt="Logo" boxSize="40px" mr={3} />
            <Heading size="md" color="gray.700">
              ObjectionGuru
            </Heading>
          </Flex>
        </Container>
      </Flex>

      {/* Main Section */}
      <Box w="full" bg="white">
        <Container maxW="container.xl" py={[4, 10]}>
          <SimpleGrid columns={[1, null, 2]} spacing={10}>
            <Box textAlign="left" py={[5, 10]} px={5} maxW="700px" mx="auto" bg="white">
              <Tag> AI Real-Time Role-Play Call Simulator</Tag>
              <Heading as="h1" size="2xl" mb={4} color="teal.800" lineHeight="shorter">
                Enhance Your Deal-Closing Skills with AI-Driven Real-Life Scenarios
              </Heading>
              <Text
                mb={6}
                fontSize={['sm', 'md', 'lg']}
                color="gray.600"
                display={['none', 'block']}
              >
                Master the art of negotiation and objection handling with this innovative AI role-playing platform.
                Train your sales team with lifelike scenarios tailored to your industry for improved readiness and conversions.
              </Text>
              <Box display={['block', 'none']} mt={4}>
                <video muted autoPlay loop>
                  <source src="./cold-calling2.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </Box>
              <InputGroup size="md" mt={4} maxW={['100%', '100%', '500px']}>
                <InputLeftElement pointerEvents="none">
                  <FaEnvelope color="gray.300" />
                </InputLeftElement>
                <Input
                  placeholder="Enter your email"
                  value={email}
                  onChange={handleEmailChange}
                  size="md"
                  variant="outline"
                  borderRadius="md"
                  boxShadow="sm"
                  _focus={{ borderColor: '#2c3192', boxShadow: 'outline' }}
                  paddingLeft="2.5rem"
                  bg="white"
                  borderColor="gray.300"
                />
                <InputRightElement width="auto">
                  <Button
                    bg="#2c3192"
                    color="white"
                    onClick={handleBuildSalesCoach}
                    size="sm"
                    px={4}
                    _hover={{ bg: '#24276b' }}
                  >
                    Sign up for free
                  </Button>
                </InputRightElement>
              </InputGroup>
            </Box>
            <Box display={['none', 'block']} mt={20}>
              <video muted autoPlay loop>
                <source src="./cold-calling2.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </Box>
          </SimpleGrid>
        </Container>
      </Box>

      {/* Other sections remain unchanged */}
      {/* Elevate Your Sales with Tailored Solutions */}
      <Box w="full" bg="gray.100" py={10}>
        <Container maxW="container.xl">
          <Heading mb={2} size="lg" color="gray.800" textAlign="center">
            Elevate Your Sales Effectiveness
          </Heading>
          <Text mb={6} fontSize="md" color="gray.600" textAlign="center">
            Discover how our platform can transform your sales approach with tailor-made solutions.
          </Text>
          <SimpleGrid columns={[1, null, 3]} spacing={8}>
            <Box textAlign="center" p={5}>
              <Icon as={FaLightbulb} w={10} h={10} color="blue.500" mb={4} />
              <Heading size="md" color="gray.800" mb={2}>
                Transform Practice to Perfection
              </Heading>
              <Text color="gray.600">
                Engage in lifelike scenarios to ensure your reps are always on message. Enjoy consistent practice designed to mirror your real product and market.
              </Text>
            </Box>
            <Box textAlign="center" p={5}>
              <Icon as={FaStopwatch} w={10} h={10} color="blue.500" mb={4} />
              <Heading size="md" color="gray.800" mb={2}>
                Swiftly Ramp New Talent
              </Heading>
              <Text color="gray.600">
                Reduce onboarding time, swiftly bringing new reps up to speed. Leverage AI-driven insights for real-time improvement.
              </Text>
            </Box>
            <Box textAlign="center" p={5}>
              <Icon as={FaChartLine} w={10} h={10} color="blue.500" mb={4} />
              <Heading size="md" color="gray.800" mb={2}>
                Data-Driven Success
              </Heading>
              <Text color="gray.600">
                Gain analytics that matter. Go beyond basic metrics to understand adherence, persuasion, and successful message delivery.
              </Text>
            </Box>
          </SimpleGrid>
        </Container>
      </Box>

      <Box w="full" bg="white" py={10}>
        <Container maxW="container.xl">
          <Heading mb={2} size="lg" color="gray.800" textAlign="center">
            Build Your AI Sales Coach in Minutes
          </Heading>
          <Text mb={6} fontSize="md" color="gray.600" textAlign="center">
            Create role-playing scenarios for your challenges: Hiring, Onboarding, Objection Handling, Discovery, and more. Generate initial assessments for your team and uncover new opportunities for improvement. Get real-time metrics on improvements, skill gaps, and training status.
          </Text>
          <Box display="flex" justifyContent="center" alignItems="center" mb={8}>
            <Image src="./score-call.png" alt="Score Call" />
          </Box>
          <Heading mb={4} size="md" color="gray.800" textAlign="center">
            Get Started in Just 3 Simple Steps
          </Heading>
          <SimpleGrid columns={[1, null, 3]} spacing={8}>
            <VStack textAlign="center" p={5} bg="gray.50" borderRadius="md" boxShadow="sm">
              <Icon as={FaCogs} w={10} h={10} color="blue.500" mb={4} />
              <Heading size="md" color="gray.800" mb={2}>
                Customize Your Sales Coach
              </Heading>
              <Text color="gray.600">
                Define challenges, language, objections, and everything to make it tailored to your needs.
              </Text>
            </VStack>
            <VStack textAlign="center" p={5} bg="gray.50" borderRadius="md" boxShadow="sm">
              <Icon as={FaClipboardCheck} w={10} h={10} color="blue.500" mb={4} />
              <Heading size="md" color="gray.800" mb={2}>
                Generate Assessments and Training Plans
              </Heading>
              <Text color="gray.600">
                Create assessments to identify skill gaps and develop targeted training plans.
              </Text>
            </VStack>
            <VStack textAlign="center" p={5} bg="gray.50" borderRadius="md" boxShadow="sm">
              <Icon as={FaChartLine} w={10} h={10} color="blue.500" mb={4} />
              <Heading size="md" color="gray.800" mb={2}>
                Get Real-Time Insights
              </Heading>
              <Text color="gray.600">
                Access real-time insights about progress and skill improvements.
              </Text>
            </VStack>
          </SimpleGrid>
          <Divider mt={6} />
        </Container>
      </Box>

      <Box w="full" bg="gray.50" py={10}>
        <Container maxW="container.xl">
          <Heading mb={6} size="lg" color="gray.800" textAlign="center">
            Best ROI on the Market
          </Heading>
          <Text mb={10} fontSize="md" color="gray.600" textAlign="center">
            Pay per hour of training. Save up to 72% on hourly training costs, allowing top performers to focus on selling while automating training.
          </Text>
          <Text textAlign="center" fontSize="md" mb={10}>
            <strong>If you don't like it within the first 15 days, you can ask for a refund, no questions asked.</strong>
          </Text>
          <SimpleGrid columns={[1, null, 3]} spacing={8}>
            <VStack
              p={5}
              bg="white"
              borderRadius="md"
              boxShadow="md"
              textAlign="center"
              spacing={4}
            >
              <Icon as={FaLightbulb} w={8} h={8} color="teal.600" />
              <Heading size="md" color="teal.600">
                Light Package
              </Heading>
              <Text fontSize="2xl" fontWeight="bold" color="gray.800">
                $50 USD
              </Text>
              <Text fontSize="sm" color="gray.500">
                monthly*
              </Text>
              <HStack justifyContent="space-between" w="full">
                <Text fontSize="md" color="gray.600">
                  2 hours of monthly training
                </Text>
                <Icon as={FaClock} w={6} h={6} color="blue.500" />
              </HStack>
              <HStack justifyContent="space-between" w="full">
                <Text fontSize="md" color="gray.600">
                  Unlimited users
                </Text>
                <Icon as={FaUsers} w={6} h={6} color="blue.500" />
              </HStack>
              <HStack justifyContent="space-between" w="full">
                <Text fontSize="md" color="gray.600">
                  2 assistants included
                </Text>
                <Icon as={FaUserTie} w={6} h={6} color="blue.500" />
              </HStack>
            </VStack>

            <VStack
              p={5}
              bg="white"
              borderRadius="md"
              boxShadow="md"
              textAlign="center"
              spacing={4}
            >
              <Icon as={FaStar} w={8} h={8} color="teal.600" />
              <Heading size="md" color="teal.600">
                Standard Package
              </Heading>
              <Text fontSize="2xl" fontWeight="bold" color="gray.800">
                $100 USD
              </Text>
              <Text fontSize="sm" color="gray.500">
                monthly*
              </Text>
              <HStack justifyContent="space-between" w="full">
                <Text fontSize="md" color="gray.600">
                  6 hours of monthly training
                </Text>
                <Icon as={FaClock} w={6} h={6} color="blue.500" />
              </HStack>
              <HStack justifyContent="space-between" w="full">
                <Text fontSize="md" color="gray.600">
                  Unlimited users
                </Text>
                <Icon as={FaUsers} w={6} h={6} color="blue.500" />
              </HStack>
              <HStack justifyContent="space-between" w="full">
                <Text fontSize="md" color="gray.600">
                  Interview Assessments
                </Text>
                <Icon as={FaClipboardCheck} w={6} h={6} color="blue.500" />
              </HStack>
              <HStack justifyContent="space-between" w="full">
                <Text fontSize="md" color="gray.600">
                  5 assistants included
                </Text>
                <Icon as={FaUserTie} w={6} h={6} color="blue.500" />
              </HStack>
            </VStack>

            <VStack
              p={5}
              bg="white"
              borderRadius="md"
              boxShadow="md"
              textAlign="center"
              spacing={4}
            >
              <Icon as={FaCrown} w={8} h={8} color="teal.600" />
              <Heading size="md" color="teal.600">
                Premium Package
              </Heading>
              <Text fontSize="2xl" fontWeight="bold" color="gray.800">
                $230 USD
              </Text>
              <Text fontSize="sm" color="gray.500">
                monthly*
              </Text>
              <HStack justifyContent="space-between" w="full">
                <Text fontSize="md" color="gray.600">
                  15 hours of monthly training
                </Text>
                <Icon as={FaClock} w={6} h={6} color="blue.500" />
              </HStack>
              <HStack justifyContent="space-between" w="full">
                <Text fontSize="md" color="gray.600">
                  Unlimited users
                </Text>
                <Icon as={FaUsers} w={6} h={6} color="blue.500" />
              </HStack>
              <HStack justifyContent="space-between" w="full">
                <Text fontSize="md" color="gray.600">
                  Interview Assessments
                </Text>
                <Icon as={FaClipboardCheck} w={6} h={6} color="blue.500" />
              </HStack>
              <HStack justifyContent="space-between" w="full">
                <Text fontSize="md" color="gray.600">
                  Customized Training Plans
                </Text>
                <Icon as={FaChalkboardTeacher} w={6} h={6} color="blue.500" />
              </HStack>
              <HStack justifyContent="space-between" w="full">
                <Text fontSize="md" color="gray.600">
                  10 assistants included
                </Text>
                <Icon as={FaUserTie} w={6} h={6} color="blue.500" />
              </HStack>
            </VStack>
          </SimpleGrid>
          <Box textAlign="center" mt={5}>
          <Text> * Price when paying annually</Text>
          </Box>
        </Container>
      </Box>

      {/* Join the Elite of Sales Innovators */}
      <Box w="full" bg="gray.100" py={10}>
        <Container maxW="container.xl">
          <Flex direction="column" align="center">
            <Icon as={FaUsers} w={14} h={14} color="blue.500" mb={6} />
            <Heading size="lg" color="gray.800" mb={2}>
              Join the AI Sales Revolution
            </Heading>
            <Text fontSize="lg" color="gray.600" textAlign="center" maxW="600px">
              Become part of a thriving community of sales professionals leveraging AI to enhance their skills and drive success. Join others who are transforming their sales strategies with cutting-edge technology.
            </Text>
            <InputGroup size="md" mt={4} maxW={['100%', '100%', '500px']}>
              <InputLeftElement pointerEvents="none">
                <FaEnvelope color="gray.300" />
              </InputLeftElement>
              <Input
                placeholder="Enter your email"
                value={email}
                onChange={handleEmailChange}
                size="md"
                variant="outline"
                borderRadius="md"
                boxShadow="sm"
                _focus={{ borderColor: '#2c3192', boxShadow: 'outline' }}
                paddingLeft="2.5rem"
                bg="white"
                borderColor="gray.300"
              />
              <InputRightElement width="auto">
                <Button
                  bg="#2c3192"
                  color="white"
                  onClick={handleBuildSalesCoach}
                  size="sm"
                  px={4}
                  _hover={{ bg: '#24276b' }}
                >
                  Sign up for free
                </Button>
              </InputRightElement>
            </InputGroup>
          </Flex>
        </Container>
      </Box>

      {/* Footer */}
      <Flex w="full" p={4} bg="white" align="center" justify="center" boxShadow="md">
        <Container maxW="container.xl">
          <Text color="gray.700" textAlign="center">
            &copy; {new Date().getFullYear()} ObjectionGuru. All Rights Reserved.
          </Text>
        </Container>
      </Flex>
    </Flex>
  );
};

export default LandingPage;