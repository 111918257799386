import React, { useState } from 'react';
import {
  Box,
  Flex,
  Icon,
  Text,
  IconButton,
  Link as ChakraLink,
  useColorModeValue,
  Tooltip,
} from '@chakra-ui/react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { FaRobot, FaChevronLeft, FaChevronRight, FaHistory, FaClipboardList, FaCog } from 'react-icons/fa';
import { User } from 'firebase/auth';

interface MenuLayoutProps {
  children: React.ReactNode;
  user: User | null;
}

const MENU_BG = "rgb(36, 39, 107)";
const MENU_HOVER = "rgb(46, 49, 127)";
const MENU_ACTIVE = "rgb(56, 59, 137)";
const SIDEBAR_WIDTH = "256px";
const COLLAPSED_WIDTH = "64px";

const MenuLayout: React.FC<MenuLayoutProps> = ({ children, user }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const location = useLocation();
  
  if (!user) {
    return <>{children}</>;
  }

  const menuItems = [
    { icon: FaRobot, label: 'Assistants', path: '/assistants' },
    { icon: FaHistory, label: 'Sessions', path: '/sessions' },
    { icon: FaClipboardList, label: 'Training Plans', path: '/training' },
    { icon: FaCog, label: 'Configuration', path: '/config' },
  ];

  return (
    <Flex minH="calc(100vh - 64px)" position="relative">
      {/* Fixed Sidebar */}
      <Box
        position="fixed"
        left={0}
        top="64px"
        bottom={0}
        w={isCollapsed ? COLLAPSED_WIDTH : SIDEBAR_WIDTH}
        bg={MENU_BG}
        transition="width 0.2s"
        borderRight="1px"
        borderColor="whiteAlpha.200"
        zIndex={99}
      >
        <Flex direction="column" h="full">
          {/* Menu Items Container */}
          <Box flex="1" py={4} overflowY="auto">
            {menuItems.map((item) => {
              const isActive = location.pathname === item.path;
              return (
                <Tooltip
                  key={item.path}
                  label={isCollapsed ? item.label : ''}
                  placement="right"
                  isDisabled={!isCollapsed}
                >
                  <ChakraLink
                    as={RouterLink}
                    to={item.path}
                    _hover={{ textDecoration: 'none' }}
                    display="block"
                  >
                    <Flex
                      align="center"
                      p={3}
                      mx={2}
                      borderRadius="md"
                      bg={isActive ? MENU_ACTIVE : 'transparent'}
                      color="white"
                      _hover={{
                        bg: isActive ? MENU_ACTIVE : MENU_HOVER,
                      }}
                      transition="all 0.2s"
                    >
                      <Icon as={item.icon} boxSize={5} />
                      {!isCollapsed && (
                        <Text ml={4} fontSize="sm" fontWeight="medium">
                          {item.label}
                        </Text>
                      )}
                    </Flex>
                  </ChakraLink>
                </Tooltip>
              );
            })}
          </Box>

          {/* Collapse Button */}
          <Box p={2} borderTop="1px" borderColor="whiteAlpha.200">
            <IconButton
              aria-label={isCollapsed ? 'Expand menu' : 'Collapse menu'}
              icon={isCollapsed ? <FaChevronRight /> : <FaChevronLeft />}
              onClick={() => setIsCollapsed(!isCollapsed)}
              variant="ghost"
              size="sm"
              width="full"
              color="white"
              _hover={{ bg: MENU_HOVER }}
            />
          </Box>
        </Flex>
      </Box>

      {/* Main Content Area - Pushed right by sidebar width */}
      <Box
        ml={isCollapsed ? COLLAPSED_WIDTH : SIDEBAR_WIDTH}
        flex={1}
        transition="margin-left 0.2s"
        bg={useColorModeValue('gray.50', 'gray.900')}
      >
        <Box
          maxW="1400px"
          w="full"
          mx="auto"
          px={{ base: 4, md: 6, lg: 8 }}
          py={6}
        >
          {children}
        </Box>
      </Box>
    </Flex>
  );
};

export default MenuLayout; 