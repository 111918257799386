import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  VStack,
  Heading,
  Text,
  Progress,
  List,
  ListItem,
  ListIcon,
  Card,
  CardBody,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Button,
  Avatar,
} from '@chakra-ui/react';
import { MdCheckCircle, MdWarning } from 'react-icons/md';
import { subscribeToSessionWithMessages } from '../utils/firebase.utils';
import type { SessionData, Message, MessageContent, Evaluation, EvaluationScore } from '../utils/firebase.utils';

const formatTimestamp = (timestamp: string | Date) => {
  if (!timestamp) return '';
  const date = new Date(timestamp);
  return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};

export function RolePlayScore() {
  const { sessionId } = useParams();
  const [sessionData, setSessionData] = useState<SessionData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!sessionId) {
      setError('No session ID provided');
      setLoading(false);
      return;
    }

    const unsubscribe = subscribeToSessionWithMessages(
      sessionId,
      (data: SessionData) => {
        console.log(data);
        setSessionData(data);
        if (data?.assistantEvaluation?.evaluation?.overall_score) {
          setLoading(false);
        }
        setError(null);
      },
      (error: Error) => {
        setError(error.message);
        setSessionData(null);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [sessionId]);

  const renderSkillScores = (evaluation: Evaluation) => {
    const skills = [
      'objection_handling',
      'closing_techniques',
      'resilience',
      'active_listening',
      'communication_skills',
      'rapport_building'
    ] as const;

    return (
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
        {skills.map((skill) => (
          <Card key={skill}>
            <CardBody>
              <Stat>
                <StatLabel>{skill.replace(/_/g, ' ').toUpperCase()}</StatLabel>
                <StatNumber>{evaluation[skill]?.score ?? 'N/A'}/10</StatNumber>
                <StatHelpText>{evaluation[skill]?.description ?? 'No description available'}</StatHelpText>
              </Stat>
            </CardBody>
          </Card>
        ))}
      </SimpleGrid>
    );
  };

  if (loading || !sessionData?.assistantEvaluation?.evaluation?.overall_score) {
    return (
      <Box p={8}>
        <Progress size="xs" isIndeterminate />
        <Text mt={4}>Analyzing your role-play session...</Text>
      </Box>
    );
  }

  if (error || !sessionData) {
    return (
      <Box p={8}>
        <Text color="red.500">{error || 'No data available'}</Text>
      </Box>
    );
  }

  return (
    <Box maxW="1200px" mx="auto" p={8}>
      <VStack spacing={8} align="stretch">
        <Heading size="xl" textAlign="center">Role Play Session Review</Heading>
        
        {/* Overall Score */}
        <Card bg="blue.50">
          <CardBody textAlign="center">
            <Heading size="md" mb={4}>Overall Score</Heading>
            <Text fontSize="4xl" fontWeight="bold" color="blue.600">
              {sessionData.assistantEvaluation?.evaluation?.overall_score ?? 'N/A'}/10
            </Text>
          </CardBody>
        </Card>

        {/* Highlights and Opportunities */}
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
          <Card>
            <CardBody>
              <Heading size="md" mb={4} color="green.600">Highlights</Heading>
              <Text>{sessionData.assistantEvaluation?.evaluation?.highlights ?? 'No highlights available'}</Text>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Heading size="md" mb={4} color="orange.600">Improvement Opportunities</Heading>
              <Text>{sessionData.assistantEvaluation?.evaluation?.opportunities ?? 'No opportunities available'}</Text>
            </CardBody>
          </Card>
        </SimpleGrid>

        {/* Detailed Skill Scores */}
        <Box>
          <Heading size="md" mb={6}>Detailed Skills Assessment</Heading>
          {sessionData.assistantEvaluation?.evaluation && 
            renderSkillScores(sessionData.assistantEvaluation.evaluation)}
        </Box>

        {/* Conversation History */}
        <Card>
          <CardBody>
            <Heading size="md" mb={6}>Conversation History</Heading>
            <Box height="400px" overflowY="auto">
              <VStack spacing={4} align="stretch">
                {sessionData.messages?.slice(1).map((message) => (
                  <Box 
                    key={message.id}
                    display="flex"
                    gap={4}
                    alignItems="flex-start"
                    flexDirection={message.role === 'assistant' ? 'row' : 'row-reverse'}
                  >
                    <Avatar 
                      size="sm"
                      name={message.role === 'assistant' ? sessionData.assistantName : 'User'}
                      bg={message.role === 'assistant' ? 'blue.500' : 'gray.500'}
                    />
                    <Box
                      maxW="70%"
                      bg={message.role === 'assistant' ? 'blue.50' : 'gray.50'}
                      p={3}
                      pb={6}
                      borderRadius="lg"
                      position="relative"
                    >
                      <Text fontSize="sm" mb={1} color="gray.500">
                        {message.role === 'assistant' ? sessionData.assistantName : 'You'}
                      </Text>
                      <Text mb={4}>
                        {message.content?.text}
                      </Text>
                      <Text 
                        fontSize="xs" 
                        color="gray.500" 
                        position="absolute"
                        bottom={2}
                        right={3}
                      >
                        {formatTimestamp(message.timestamp)}
                      </Text>
                    </Box>
                  </Box>
                ))}
              </VStack>
            </Box>
          </CardBody>
        </Card>

        {/* Session Details */}
        <Card>
          <CardBody>
            <VStack spacing={4} align="stretch">
              <Heading size="md">Session Details</Heading>
              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                <Box>
                  <Text fontWeight="bold">Assistant Name</Text>
                  <Text>{sessionData.assistantName}</Text>
                </Box>
                <Box>
                  <Text fontWeight="bold">Status</Text>
                  <Text>{sessionData.status}</Text>
                </Box>
                <Box>
                  <Text fontWeight="bold">Finished At</Text>
                  <Text>
                    {sessionData.finishedAt instanceof Date 
                      ? sessionData.finishedAt.toLocaleString()
                      : 'Not finished'}
                  </Text>
                </Box>
                <Box>
                  <Text fontWeight="bold">Description</Text>
                  <Text>{sessionData.description}</Text>
                </Box>
              </SimpleGrid>

              {sessionData.allowRetry && (
                <Button colorScheme="blue" mt={4}>
                  Try Again
                </Button>
              )}
            </VStack>
          </CardBody>
        </Card>
      </VStack>
    </Box>
  );
} 