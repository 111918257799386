import React, { useEffect, useState } from 'react';
import { 
  Box, 
  Heading, 
  VStack, 
  Text, 
  Card, 
  CardBody, 
  Badge, 
  SimpleGrid,
  Spinner,
  useToast,
  HStack,
  Icon,
  Divider,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber
} from '@chakra-ui/react';
import { getSessionsByAccount } from '../utils/firebase.utils';
import { useNavigate } from 'react-router-dom';
import { FaCalendar, FaClock, FaStar } from 'react-icons/fa';

interface SkillEvaluation {
  score: number;
  description: string;
}

interface Session {
  id: string;
  status: string;
  createdAt: any;
  coachId: string;
  finishedAt?: any;
  scoredAt?: any;
  assistantEvaluation?: {
    evaluation: {
      overall_score: number;
      highlights: string;
      opportunities: string;
      rapport_building: SkillEvaluation;
      communication_skills: SkillEvaluation;
      closing_techniques: SkillEvaluation;
      resilience: SkillEvaluation;
      active_listening: SkillEvaluation;
      objection_handling: SkillEvaluation;
    };
  };
}

const SessionsList: React.FC = () => {
  const [sessions, setSessions] = useState<Session[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    const fetchSessions = async () => {
      try {
        const sessionsList = await getSessionsByAccount();
        setSessions(sessionsList as Session[]);
      } catch (error) {
        toast({
          title: 'Error',
          description: 'Failed to load sessions',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchSessions();
  }, [toast]);

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'active':
        return 'green';
      case 'finished':
        return 'blue';
      default:
        return 'gray';
    }
  };

  const formatDate = (timestamp: any) => {
    if (!timestamp) return 'No date';
    
    try {
      // Handle Firestore Timestamp
      if (timestamp.toDate) {
        return timestamp.toDate().toLocaleString();
      }
      
      // Handle seconds timestamp
      if (timestamp.seconds) {
        return new Date(timestamp.seconds * 1000).toLocaleString();
      }
      
      // Handle Date object
      if (timestamp instanceof Date) {
        return timestamp.toLocaleString();
      }
      
      // Handle string or number
      return new Date(timestamp).toLocaleString();
    } catch (error) {
      console.error('Error formatting date:', error, timestamp);
      return 'Invalid date';
    }
  };

  if (loading) {
    return (
      <Box textAlign="center" py={10}>
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <Box p={6}>
      <Heading mb={6}>Your Practice Sessions</Heading>
      <VStack spacing={4} align="stretch">
        {sessions.length === 0 ? (
          <Text textAlign="center">No sessions found</Text>
        ) : (
          sessions
            .filter(session => {
              // Only show sessions with duration > 2 minutes
              if (!session.finishedAt?.seconds || !session.createdAt?.seconds) return false;
              const durationMinutes = Math.round((session.finishedAt.seconds - session.createdAt.seconds) / 60);
              return durationMinutes > 2;
            })
            .map((session) => (
            <Card 
              key={session.id}
              cursor="pointer"
              onClick={() => navigate(`/roleplay/${session.id}/score`)}
              _hover={{ shadow: 'lg' }}
            >
              <CardBody>
                <VStack align="stretch" spacing={4}>
                  <HStack justify="space-between">
                    <Badge colorScheme={getStatusColor(session.status)} fontSize="sm">
                      {session.status.toUpperCase()}
                    </Badge>
                    <HStack spacing={4}>
                      <HStack>
                        <Icon as={FaCalendar} color="gray.500" />
                        <Text fontSize="sm" color="gray.500">
                          {formatDate(session.createdAt)}
                        </Text>
                      </HStack>
                      {session.finishedAt && (
                        <HStack>
                          <Icon as={FaClock} color="gray.500" />
                          <Text fontSize="sm" color="gray.500">
                            Duration: {
                              session.finishedAt?.seconds && session.createdAt?.seconds
                                ? Math.round((session.finishedAt.seconds - session.createdAt.seconds) / 60)
                                : '?'
                            } min
                          </Text>
                        </HStack>
                      )}
                    </HStack>
                  </HStack>

                  {session.assistantEvaluation && (
                    <>
                      <Divider />
                      <StatGroup>
                        <Stat>
                          <StatLabel>Overall Score</StatLabel>
                          <StatNumber>
                            <HStack>
                              <Icon as={FaStar} color="yellow.400" />
                              <Text>{session.assistantEvaluation.evaluation.overall_score}/10</Text>
                            </HStack>
                          </StatNumber>
                        </Stat>
                        <Stat>
                          <StatLabel>Top Skill</StatLabel>
                          <StatNumber fontSize="md">
                            {Object.entries(session.assistantEvaluation.evaluation)
                              .filter(([key]) => 
                                key.includes('_') && 
                                typeof session.assistantEvaluation!.evaluation[key as keyof typeof session.assistantEvaluation.evaluation] === 'object'
                              )
                              .reduce((max, [key, value]) => {
                                const skillValue = value as SkillEvaluation;
                                return skillValue.score > max.score ? { name: key, score: skillValue.score } : max;
                              }, { name: '', score: 0 })
                              .name.split('_').map(word => 
                                word.charAt(0).toUpperCase() + word.slice(1)
                              ).join(' ')}
                          </StatNumber>
                        </Stat>
                      </StatGroup>
                    </>
                  )}
                </VStack>
              </CardBody>
            </Card>
          ))
        )}
      </VStack>
    </Box>
  );
};

export default SessionsList; 