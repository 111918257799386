import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
  Textarea,
  useToast,
  Container,
  Heading,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  Checkbox,
  useColorModeValue,
  Grid,
  Image,
  Text,
  Skeleton,
  SimpleGrid,
  Card,
  CardBody,
  IconButton,
  Tooltip,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverHeader,
  PopoverCloseButton,
} from '@chakra-ui/react';
import { getCoachById, updateCoach, createCoach, getAccountData, updateAccountData } from '../utils/firebase.utils';
import { EditIcon, ArrowBackIcon, AddIcon, InfoIcon } from '@chakra-ui/icons';

const callTypes = [
  { 
    value: 'cold_calling', 
    label: 'Cold Calling', 
    description: 'Reaching out to potential customers who have had no prior interaction with your company. The goal is to introduce your product and generate interest.'
  },
  { 
    value: 'warm_calling', 
    label: 'Warm Calling', 
    description: 'Contacting leads who have shown some interest in your product, such as downloading a whitepaper or signing up for a newsletter.'
  },
  { 
    value: 'follow_up_calls', 
    label: 'Follow-up Calls', 
    description: 'Engaging with prospects who have previously interacted with your sales or marketing team, perhaps after a demo or a webinar.'
  },
  { 
    value: 'demo_calls', 
    label: 'Demo Calls', 
    description: 'Conducting a demonstration of your product to showcase its features and benefits to potential customers.'
  },
  { 
    value: 'discovery_calls', 
    label: 'Discovery Calls', 
    description: 'Understanding the prospect\'s needs, challenges, and goals to tailor your pitch accordingly.'
  },
  { 
    value: 'closing_calls', 
    label: 'Closing Calls', 
    description: 'Finalizing the sale by addressing any remaining objections and negotiating terms.'
  },
  { 
    value: 'onboarding_calls', 
    label: 'Onboarding Calls', 
    description: 'Assisting new customers in getting started with your product, ensuring they understand how to use it effectively.'
  },
  { 
    value: 'customer_success_calls', 
    label: 'Customer Success Calls', 
    description: 'Engaging with existing customers to ensure they are satisfied, addressing any issues, and identifying opportunities for upselling or cross-selling.'
  },
  { 
    value: 'renewal_calls', 
    label: 'Renewal Calls', 
    description: 'Discussing contract renewals with existing customers, focusing on continued value and addressing any concerns.'
  },
];

const defaultSkills = [
  { 
    id: 'rapport_building', 
    label: 'Rapport Building',
    description: 'Ability to establish trust and connection with potential customers quickly.'
  },
  { 
    id: 'communication_skills', 
    label: 'Communication Skills',
    description: 'Clear and effective verbal communication, including tone, pace, and articulation.'
  },
  { 
    id: 'closing_techniques', 
    label: 'Closing Techniques',
    description: 'Methods to guide conversations toward successful deal completion.'
  },
  { 
    id: 'resilience', 
    label: 'Resilience',
    description: 'Ability to maintain composure and persistence in face of rejection.'
  },
  { 
    id: 'active_listening', 
    label: 'Active Listening',
    description: 'Fully concentrating, understanding, responding, and remembering what customers say.'
  },
  { 
    id: 'objection_handling', 
    label: 'Objection Handling',
    description: 'Effectively addressing and overcoming customer concerns and resistance.'
  },
];

// Add language options array
const languageOptions = [
  { value: 'english', label: 'English' },
  { value: 'spanish', label: 'Spanish' },
  { value: 'portuguese', label: 'Portuguese' },
];

interface AccountData {
  id: string;
  productName?: string;
  problem?: string;
  solution?: string;
  competitors?: string;
}

interface Coach {
  id: string;
  assistantName: string;
  assistantDescription: string;
  instructions: string;
  language: string;
  imageUrl?: string;
  productName: string;
  problem: string;
  solution: string;
  competitors: string;
  callType: string;
  rejectionsBeforeSuccess: number;
  objections: string;
  skills: string[];
}

interface Instructions {
  productName: string;
  problem: string;
  solution: string;
  competitors: string;
  callType: string;
  rejectionsBeforeSuccess: number;
  objections: string;
  skills: string[];
}

const CreateEditCoach: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const toast = useToast();
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const isEditMode = !!id;

  const [formData, setFormData] = useState({
    assistantName: '',
    productName: '',
    problem: '',
    solution: '',
    competitors: '',
    callType: '',
    rejectionsBeforeSuccess: 3,
    objections: '',
    selectedSkills: defaultSkills.map(skill => skill.id),
    language: 'english',
  });

  const [customSkills, setCustomSkills] = useState<Array<{
    id: string;
    label: string;
    description: string;
  }>>([]);
  const [newSkill, setNewSkill] = useState({
    label: '',
    description: '',
  });

  const [hasLoadedAccountData, setHasLoadedAccountData] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchCoach = async () => {
      if (!isEditMode) return;
      try {
        const coachData = await getCoachById(id) as Coach | null;
        if (coachData) {
          setFormData({
            assistantName: coachData.assistantName || '',
            productName: coachData.productName || '',
            problem: coachData.problem || '',
            solution: coachData.solution || '',
            competitors: coachData.competitors || '',
            callType: coachData.callType || '',
            rejectionsBeforeSuccess: coachData.rejectionsBeforeSuccess || 3,
            objections: coachData.objections || '',
            selectedSkills: coachData.skills || defaultSkills.map(skill => skill.id),
            language: coachData.language || 'english',
          });
        }
      } catch (error) {
        toast({
          title: 'Error fetching coach',
          description: 'Unable to load coach details',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    };

    fetchCoach();
  }, [id, isEditMode, toast]);

  useEffect(() => {
    const fetchAccountData = async () => {
      if (!isEditMode && !hasLoadedAccountData) {
        try {
          const accountData = await getAccountData() as AccountData | null;
          if (accountData) {
            // Only update form if account data exists and fields are empty
            setFormData(prevData => ({
              ...prevData,
              productName: prevData.productName || accountData.productName || '',
              problem: prevData.problem || accountData.problem || '',
              solution: prevData.solution || accountData.solution || '',
              competitors: prevData.competitors || accountData.competitors || '',
            }));

            // If account data is empty, update it with the form data
            if (!accountData.productName && formData.productName) {
              await updateAccountData({
                productName: formData.productName,
                problem: formData.problem,
                solution: formData.solution,
                competitors: formData.competitors,
              });
            }
          }
          setHasLoadedAccountData(true);
        } catch (error) {
          console.error('Error loading account data:', error);
          toast({
            title: 'Error loading account data',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      }
    };

    fetchAccountData();
  }, [isEditMode, hasLoadedAccountData, toast]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const coachData = {
        assistantName: formData.assistantName,
        assistantDescription: `${formData.productName} - ${formData.problem}`,
        instructions: '', // Keep empty as we'll use separate fields
        language: formData.language,
        // Add separate fields for configuration
        productName: formData.productName,
        problem: formData.problem,
        solution: formData.solution,
        competitors: formData.competitors,
        callType: formData.callType,
        rejectionsBeforeSuccess: formData.rejectionsBeforeSuccess,
        objections: formData.objections,
        skills: formData.selectedSkills,
      };

      if (isEditMode && id) {
        await updateCoach(id, coachData);
      } else {
        await createCoach(coachData);
        
        const accountData = await getAccountData() as AccountData | null;
        if (accountData && !accountData.productName) {
          await updateAccountData({
            productName: formData.productName,
            problem: formData.problem,
            solution: formData.solution,
            competitors: formData.competitors,
          });
        }
      }

      toast({
        title: `Coach ${isEditMode ? 'updated' : 'created'} successfully!`,
        status: 'success',
        duration: 3000,
      });

      navigate('/');
    } catch (error) {
      console.error('Error saving coach:', error);
      toast({
        title: 'Error',
        description: `Failed to ${isEditMode ? 'update' : 'create'} coach`,
        status: 'error',
        duration: 3000,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAddCustomSkill = () => {
    if (newSkill.label.trim()) {
      const skillId = newSkill.label.toLowerCase().replace(/\s+/g, '_');
      const newCustomSkill = {
        id: skillId,
        label: newSkill.label,
        description: newSkill.description,
      };
      setCustomSkills([...customSkills, newCustomSkill]);
      setFormData({
        ...formData,
        selectedSkills: [...formData.selectedSkills, skillId],
      });
      setNewSkill({ label: '', description: '' });
    }
  };

  return (
    <Box maxW="1200px" mx="auto" py={8} px={4}>
      <Button
        leftIcon={<ArrowBackIcon />}
        onClick={() => navigate('/')}
        mb={6}
        variant="ghost"
        size="lg"
      >
        Back to Coaches
      </Button>

      <Container maxW="800px">
        <Box
          borderWidth="1px"
          borderRadius="xl"
          p={8}
          bg={bgColor}
          borderColor={borderColor}
          shadow="lg"
        >
          <VStack spacing={6} align="stretch">
            <Heading size="lg">
              {isEditMode ? 'Edit Coach' : 'Create New Coach'}
            </Heading>

            <form onSubmit={handleSubmit} noValidate style={{ width: '100%' }}>
              <VStack spacing={6} align="stretch">
                <FormControl isRequired>
                  <FormLabel htmlFor="language">Language</FormLabel>
                  <Select
                    id="language"
                    name="language"
                    value={formData.language}
                    onChange={(e) => setFormData({ ...formData, language: e.target.value })}
                    required
                  >
                    {languageOptions.map((lang) => (
                      <option key={lang.value} value={lang.value}>
                        {lang.label}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl isRequired>
                  <FormLabel htmlFor="assistantName">Assistant Name</FormLabel>
                  <Input
                    id="assistantName"
                    name="assistantName"
                    value={formData.assistantName}
                    onChange={(e) => setFormData({ ...formData, assistantName: e.target.value })}
                    placeholder="Enter assistant name"
                    required
                  />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel htmlFor="productName">Product Name</FormLabel>
                  <Input
                    id="productName"
                    name="productName"
                    value={formData.productName}
                    onChange={(e) => setFormData({ ...formData, productName: e.target.value })}
                    placeholder="Enter product name"
                    required
                  />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel htmlFor="problem">Problem</FormLabel>
                  <Textarea
                    id="problem"
                    name="problem"
                    value={formData.problem}
                    onChange={(e) => setFormData({ ...formData, problem: e.target.value })}
                    placeholder="What problem does your product solve?"
                    required
                  />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel htmlFor="solution">Solution</FormLabel>
                  <Textarea
                    id="solution"
                    name="solution"
                    value={formData.solution}
                    onChange={(e) => setFormData({ ...formData, solution: e.target.value })}
                    placeholder="How does your product solve this problem?"
                    required
                  />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel htmlFor="competitors">Competitors</FormLabel>
                  <Textarea
                    id="competitors"
                    name="competitors"
                    value={formData.competitors}
                    onChange={(e) => setFormData({ ...formData, competitors: e.target.value })}
                    placeholder="Who are your main competitors?"
                    required
                  />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel htmlFor="callType">Call Type</FormLabel>
                  <Select
                    id="callType"
                    name="callType"
                    value={formData.callType}
                    onChange={(e) => setFormData({ ...formData, callType: e.target.value })}
                    required
                  >
                    <option value="">Select call type</option>
                    {callTypes.map((type) => (
                      <option key={type.value} value={type.value} title={type.description}>
                        {type.label}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel>Number of rejections before success</FormLabel>
                  <Slider
                    value={formData.rejectionsBeforeSuccess}
                    min={1}
                    max={5}
                    step={1}
                    onChange={(val) => setFormData({...formData, rejectionsBeforeSuccess: val})}
                  >
                    <SliderMark value={1}>1</SliderMark>
                    <SliderMark value={3}>3</SliderMark>
                    <SliderMark value={5}>5</SliderMark>
                    <SliderTrack>
                      <SliderFilledTrack />
                    </SliderTrack>
                    <SliderThumb />
                  </Slider>
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Top Objections</FormLabel>
                  <Textarea
                    value={formData.objections}
                    onChange={(e) => setFormData({...formData, objections: e.target.value})}
                    placeholder="List the top objections you typically encounter"
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Skills to Track</FormLabel>
                  <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4} mb={4}>
                    {[...defaultSkills, ...customSkills].map(skill => (
                      <Card 
                        key={skill.id}
                        variant={formData.selectedSkills.includes(skill.id) ? 'filled' : 'outline'}
                        borderColor={formData.selectedSkills.includes(skill.id) ? 'blue.500' : 'gray.200'}
                      >
                        <CardBody>
                          <Checkbox
                            isChecked={formData.selectedSkills.includes(skill.id)}
                            onChange={(e) => {
                              const newSkills = e.target.checked
                                ? [...formData.selectedSkills, skill.id]
                                : formData.selectedSkills.filter(s => s !== skill.id);
                              setFormData({...formData, selectedSkills: newSkills});
                            }}
                          >
                            <Box display="flex" alignItems="center">
                              {skill.label}
                              <Tooltip label={skill.description} placement="top">
                                <InfoIcon ml={2} color="gray.500" />
                              </Tooltip>
                            </Box>
                          </Checkbox>
                        </CardBody>
                      </Card>
                    ))}
                    
                    <Card variant="outline" borderStyle="dashed">
                      <CardBody>
                        <Popover placement="right">
                          <PopoverTrigger>
                            <Button
                              leftIcon={<AddIcon />}
                              variant="ghost"
                              width="full"
                              height="full"
                            >
                              Add Custom Skill
                            </Button>
                          </PopoverTrigger>
                          <PopoverContent>
                            <PopoverHeader fontWeight="semibold">Add New Skill</PopoverHeader>
                            <PopoverCloseButton />
                            <PopoverBody>
                              <VStack spacing={3}>
                                <FormControl isRequired>
                                  <FormLabel>Skill Name</FormLabel>
                                  <Input
                                    value={newSkill.label}
                                    onChange={(e) => setNewSkill({
                                      ...newSkill,
                                      label: e.target.value
                                    })}
                                    placeholder="Enter skill name"
                                  />
                                </FormControl>
                                <FormControl>
                                  <FormLabel>Description</FormLabel>
                                  <Textarea
                                    value={newSkill.description}
                                    onChange={(e) => setNewSkill({
                                      ...newSkill,
                                      description: e.target.value
                                    })}
                                    placeholder="Enter skill description"
                                  />
                                </FormControl>
                                <Button
                                  colorScheme="blue"
                                  onClick={handleAddCustomSkill}
                                  width="full"
                                >
                                  Add Skill
                                </Button>
                              </VStack>
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </CardBody>
                    </Card>
                  </SimpleGrid>
                </FormControl>

                <Box pt={4} display="flex" gap={4}>
                  <Button
                    colorScheme="blue"
                    type="submit"
                    size="lg"
                    width="full"
                    isLoading={isSubmitting}
                  >
                    {isEditMode ? 'Save Changes' : 'Create Coach'}
                  </Button>
                  <Button
                    size="lg"
                    onClick={() => navigate('/')}
                    variant="outline"
                    width="full"
                  >
                    Cancel
                  </Button>
                </Box>
              </VStack>
            </form>
          </VStack>
        </Box>
      </Container>
    </Box>
  );
};

export default CreateEditCoach; 