import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import Header from './Header';
import { User } from 'firebase/auth';
import { Navigate } from 'react-router-dom';

interface LayoutProps {
  children: React.ReactNode;
  user: User | null;
}

const Layout: React.FC<LayoutProps> = ({ children, user }) => {
  // Redirect to login if no user
  if (!user && window.location.pathname !== '/login') {
    return <Navigate to="/login" />;
  }

  return (
    <Box minH="100vh" bg="gray.50">
      {/* Fixed Header */}
      <Box 
        position="fixed"
        top={0}
        left={0}
        right={0}
        zIndex={1000}
        bg="white"
        borderBottom="1px"
        borderColor="gray.200"
      >
        <Header user={user} />
      </Box>

      {/* Main Content - Pushed down by header height */}
      <Box pt="64px" minH="calc(100vh - 64px)">
        {children}
      </Box>
    </Box>
  );
};

export default Layout;