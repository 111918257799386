import React, { useEffect, useState } from 'react';
import { getCoaches } from '../utils/firebase.utils';
import {
  Grid,
  Box,
  Image,
  Text,
  Button,
  Heading,
  VStack,
  Skeleton,
  useColorModeValue,
  Wrap,
  WrapItem,
  Tag,
  HStack,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { AddIcon, EditIcon } from '@chakra-ui/icons';

interface Coach {
  id: string;
  assistantName: string;
  productName: string;
  skills: string[];  // Array of skills
  language: string;
  callType: string;  // Add callType to interface
  imageUrl?: string;
}

interface CoachesListProps {
  language?: string;
}

const DEFAULT_PROFILE_IMAGES = [
  '/Profile.png',
  '/profile2.png',
];

let lastUsedImageIndex = -1;

const getRandomProfileImage = () => {
  // If last used was first image (0), use second image (1), and vice versa
  lastUsedImageIndex = lastUsedImageIndex === 0 ? 1 : 0;
  return DEFAULT_PROFILE_IMAGES[lastUsedImageIndex];
};

const CoachesList: React.FC<CoachesListProps> = ({ language }) => {
  const [coaches, setCoaches] = useState<Coach[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCoaches = async () => {
      try {
        const coachesData = await getCoaches();
        setCoaches(coachesData as Coach[]);
      } catch (error) {
        console.error('Error fetching coaches:', error);
        // Handle error appropriately
      } finally {
        setLoading(false);
      }
    };

    fetchCoaches();
  }, []);

  const handleStartRoleplay = (coachId: string) => {
    const sessionId = crypto.randomUUID();
    navigate(`/roleplay/${sessionId}?coachId=${coachId}`);
  };

  const handleCreateCoach = () => {
    navigate('/create-coach');
  };

  const handleEditCoach = (coachId: string) => {
    navigate(`/edit-coach/${coachId}`);
  };

  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  return (
    <Box maxW="1200px" mx="auto">
      <Grid
        templateColumns={{ 
          base: '1fr', 
          md: 'repeat(2, 1fr)', 
          lg: 'repeat(3, 1fr)' 
        }}
        gap={8}
        p={8}
      >
        {loading ? (
          <>
            {[1, 2, 3].map((n) => (
              <Box
                key={n}
                borderWidth="1px"
                borderRadius="xl"
                overflow="hidden"
                borderColor={borderColor}
                bg={bgColor}
              >
                <Skeleton height="250px" />
                <VStack p={6} spacing={3} align="stretch">
                  <Skeleton height="24px" width="70%" />
                  <Skeleton height="16px" />
                  <Skeleton height="16px" width="80%" />
                  <Skeleton height="40px" />
                </VStack>
              </Box>
            ))}
          </>
        ) : (
          coaches.map(coach => (
            <Box
              key={coach.id}
              borderWidth="1px"
              borderRadius="xl"
              overflow="hidden"
              borderColor={borderColor}
              bg={bgColor}
              _hover={{ 
                shadow: '2xl',
                transform: 'translateY(-4px)'
              }}
              transition="all 0.3s ease"
            >
              <Box position="relative">
                <Image
                  src={coach.imageUrl || getRandomProfileImage()}
                  alt={coach.assistantName}
                  height="250px"
                  width="100%"
                  objectFit="cover"
                  fallback={<Skeleton height="250px" />}
                />
                <Button
                  position="absolute"
                  top={2}
                  right={2}
                  colorScheme="blue"
                  size="sm"
                  onClick={() => handleEditCoach(coach.id)}
                  leftIcon={<EditIcon />}
                  opacity={0.9}
                >
                  Edit
                </Button>
              </Box>
              <VStack p={6} spacing={3} align="stretch">
                <Heading size="md" noOfLines={1}>
                  {coach.assistantName}
                </Heading>
                <HStack spacing={2} wrap="wrap">
                  <Tag
                    size="md"
                    variant="solid"
                    colorScheme={
                      coach.callType?.includes('cold') ? 'red' :
                      coach.callType?.includes('warm') ? 'orange' :
                      coach.callType?.includes('follow') ? 'green' :
                      coach.callType?.includes('demo') ? 'purple' :
                      'blue'
                    }
                  >
                    {coach.callType?.split('_')
                      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                      .join(' ')}
                  </Tag>
                  <Tag
                    size="md"
                    variant="subtle"
                    colorScheme="cyan"
                  >
                    {coach.language.charAt(0).toUpperCase() + coach.language.slice(1)}
                  </Tag>
                </HStack>
                <Text 
                  color={useColorModeValue('gray.600', 'gray.300')} 
                  fontSize="sm"
                  fontWeight="medium"
                >
                  {coach.productName}
                </Text>
                <Wrap spacing={2}>
                  {coach.skills.map((skill, index) => (
                    <WrapItem key={index}>
                      <Tag
                        size="sm"
                        variant="subtle"
                        colorScheme={
                          skill.includes('rapport') ? 'green' :
                          skill.includes('communication') ? 'blue' :
                          skill.includes('closing') ? 'purple' :
                          skill.includes('objection') ? 'orange' :
                          'gray'
                        }
                      >
                        {skill.split('_').map(word => 
                          word.charAt(0).toUpperCase() + word.slice(1)
                        ).join(' ')}
                      </Tag>
                    </WrapItem>
                  ))}
                </Wrap>
                <Button
                  colorScheme="blue"
                  size="lg"
                  onClick={() => handleStartRoleplay(coach.id)}
                  _hover={{
                    transform: 'translateY(-2px)',
                    boxShadow: 'lg',
                  }}
                  transition="all 0.2s"
                >
                  Start Roleplay Session
                </Button>
              </VStack>
            </Box>
          ))
        )}
      </Grid>
      
      <Box p={8} display="flex" justifyContent="center">
        <Button
          colorScheme="green"
          size="lg"
          onClick={handleCreateCoach}
          leftIcon={<AddIcon />}
          _hover={{
            transform: 'translateY(-2px)',
            boxShadow: 'lg',
          }}
          transition="all 0.2s"
        >
          Create New Coach
        </Button>
      </Box>
    </Box>
  );
};

export default CoachesList; 