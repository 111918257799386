// src/utils/firebase.utils.js

import { auth, db } from '../firebaseConfig';
import { collection, getDoc, doc, getDocs, getFirestore, setDoc, addDoc, serverTimestamp, updateDoc, onSnapshot, query, where, orderBy } from 'firebase/firestore';
const firestore = getFirestore();

/**
 * @typedef {Object} MessageContent
 * @property {string} text
 * @property {boolean} [audio]
 * @property {string|null} [tool]
 */

/**
 * @typedef {Object} Message
 * @property {string} id
 * @property {'assistant'|'user'} role
 * @property {MessageContent} content
 * @property {Date|string} timestamp
 * @property {string} status
 * @property {string} itemId
 * @property {string} type
 */

/**
 * @typedef {Object} EvaluationScore
 * @property {number} score
 * @property {string} description
 */

/**
 * @typedef {Object} Evaluation
 * @property {string} overall_score
 * @property {string} highlights
 * @property {string} opportunities
 * @property {EvaluationScore} rapport_building
 * @property {EvaluationScore} communication_skills
 * @property {EvaluationScore} closing_techniques
 * @property {EvaluationScore} resilience
 * @property {EvaluationScore} active_listening
 * @property {EvaluationScore} objection_handling
 */

/**
 * @typedef {Object} AssistantEvaluation
 * @property {Evaluation} evaluation
 */

/**
 * @typedef {Object} SessionData
 * @property {string} id
 * @property {string} [status]
 * @property {Date} [startedAt]
 * @property {Date|string} [finishedAt]
 * @property {string} [coachId]
 * @property {string} [assistantName]
 * @property {{ evaluation: Evaluation }} [assistantEvaluation]
 * @property {Message[]} [messages]
 * @property {string} [description]
 * @property {boolean} [allowRetry]
 */

/**
 * Helper function to get current user's domain
 */
const getCurrentUserDomain = () => {
  const user = auth.currentUser;
  if (!user?.email) throw new Error('No authenticated user found');
  return user.email.split('@')[1];
};

/**
 * Helper function to get current user's account_id
 */
const getCurrentUserAccountId = async () => {
  try {
    const domain = getCurrentUserDomain();
    const accountRef = doc(db, 'accounts', domain);
    const accountDoc = await getDoc(accountRef);
    
    if (!accountDoc.exists()) {
      throw new Error('Account not found');
    }
    
    return accountDoc.data().account_id;
  } catch (error) {
    console.error('Error getting account_id:', error);
    throw error;
  }
};

export const updateSessionStatus = async (sessionId, status) => {
  try {
    const domain = getCurrentUserDomain();
    const sessionRef = doc(db, 'sessions', sessionId);
    const sessionDoc = await getDoc(sessionRef);

    // Verify session belongs to account
    if (sessionDoc.data()?.domain !== domain) {
      throw new Error('Unauthorized access to session');
    }

    await updateDoc(sessionRef, {
      status,
      finishedAt: status === 'finished' ? serverTimestamp() : null
    });
    return true;
  } catch (error) {
    console.error('Error updating session status:', error);
    return false;
  }
};

export const getSessionWithMessages = async (sessionId) => {
  try {
    const domain = getCurrentUserDomain();
    
    // Get session document
    const sessionRef = doc(db, 'sessions', sessionId);
    const sessionSnap = await getDoc(sessionRef);
    
    if (!sessionSnap.exists()) {
      return null;
    }

    // Verify session belongs to account
    if (sessionSnap.data().domain !== domain) {
      throw new Error('Unauthorized access to session');
    }

    // Get messages subcollection
    const messagesRef = collection(sessionRef, 'messages');
    const messagesSnap = await getDocs(messagesRef);
    
    const messages = messagesSnap.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      timestamp: doc.data().timestamp?.toDate()
    })).sort((a, b) => (a.timestamp?.getTime() || 0) - (b.timestamp?.getTime() || 0));

    return {
      id: sessionSnap.id,
      ...sessionSnap.data(),
      messages
    };
  } catch (error) {
    console.error('Error fetching session:', error);
    return null;
  }
};

export const addLead = async (email) => {
  try {
    const domain = getCurrentUserDomain();
    const docRef = await addDoc(collection(db, 'leads'), {
      email,
      domain,
      date: serverTimestamp(),
    });
    return docRef.id;
  } catch (error) {
    console.error('Error adding lead: ', error);
    throw new Error('Failed to add lead');
  }
};

/**
 * Fetches or creates a session document
 * @param {string|undefined} sessionId - The ID of the session to fetch or create
 * @param {Partial<SessionData>} [initialData] - Initial data for creating a new session
 * @returns {Promise<SessionData|null>} The session data or null if not found/created
 */
export const getSessionDoc = async (sessionId, initialData = null) => {
  try {
    const sessionRef = doc(db, 'sessions', sessionId);

    // If we're getting an existing session
    if (!initialData) {
      const sessionSnap = await getDoc(sessionRef);
      if (sessionSnap.exists()) {
        return {
          id: sessionSnap.id,
          ...sessionSnap.data()
        };
      }
      return null;
    }

    // If we're creating a new session
    await setDoc(sessionRef, {
      ...initialData,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp()
    });

    // Return the newly created session data
    return {
      id: sessionId,
      ...initialData,
      createdAt: new Date(),
      updatedAt: new Date()
    };

  } catch (error) {
    console.error('Error in getSessionDoc:', error);
    throw error;
  }
};

export const getCoachDoc = async (coachId) => {
  try {
    const coachDocRef = doc(db, 'coachs', coachId);
    const coachDoc = await getDoc(coachDocRef);
    if (coachDoc.exists()) {
      return coachDoc.data();
    } else {
      console.error('No such coach document!');
      return null;
    }
  } catch (error) {
    console.error('Error fetching coach document:', error);
    return null;
  }
};

export const getApiKeyByDomain = async (domain) => {
  try {
    const apiKeyDocRef = doc(db, 'apiKeys', domain);
    const apiKeyDoc = await getDoc(apiKeyDocRef);
    if (apiKeyDoc.exists()) {
      return apiKeyDoc.data().apiKey;
    } else {
      console.error('No API key found for domain:', domain);
      return null;
    }
  } catch (error) {
    console.error('Error fetching API key by domain:', error);
    return null;
  }
};

/**
 * Fetch the API key for the current user's domain.
 */
export const fetchCurrentUserApiKey = async () => {
  try {
    const user = auth.currentUser;
    if (user && user.email) {
      const domain = user.email.split('@')[1];
      const apiKeyDoc = await getDoc(doc(db, 'apiKeys', domain));
      if (apiKeyDoc.exists()) {
        return apiKeyDoc.data().apiKey || null;
      }
    }
    return null;
  } catch (error) {
    console.error('Error fetching API key:', error);
    return null;
  }
};

export const saveCompanyData = async (domainName, data) => {
  try {
    const companyRef = doc(firestore, 'accounts', domainName);
    
    // Saving data to Firestore with provided domainName and data
    await setDoc(companyRef, data, { merge: true });

    console.log(`Company data for ${domainName} saved successfully.`);
  } catch (error) {
    console.error('Error saving company data:', error);
    throw new Error('Failed to save company data. Please try again later.');
  }
};

// Add this utility function to generate secure random account IDs
const generateSecureAccountId = () => {
  // Generate 16 random bytes
  const randomBytes = new Uint8Array(16);
  crypto.getRandomValues(randomBytes);
  
  // Convert to hex string and combine with timestamp
  const randomHex = Array.from(randomBytes)
    .map(b => b.toString(16).padStart(2, '0'))
    .join('');
    
  const timestamp = Date.now().toString(36);
  
  return `acc_${timestamp}_${randomHex}`;
};

export const checkCompanyData = async (domainName) => {
  // Attempt to fetch company data for the email domain
  const companyRef = doc(firestore, 'accounts', domainName);
  const companySnapshot = await getDoc(companyRef);

  if (companySnapshot.exists()) {
    let company_data = companySnapshot.data();
    
    // If there's no account_id, generate a secure one and update the document
    if (!company_data.account_id) {
      const account_id = generateSecureAccountId();
      await setDoc(companyRef, { ...company_data, account_id }, { merge: true });
      company_data.account_id = account_id;
    }

    return {
      companyExists: !!company_data.companyName,
      companyName: company_data.companyName,
      companyData: company_data,
      account_id: company_data.account_id
    };
  } else {
    return { companyExists: false };
  }
};

/**
 * Subscribe to real-time updates of a session and its messages
 * @param {string} sessionId - The ID of the session to subscribe to
 * @param {(data: SessionData) => void} onData - Callback function for data updates
 * @param {(error: Error) => void} onError - Callback function for errors
 * @returns {() => void} Cleanup function to unsubscribe
 */
export const subscribeToSessionWithMessages = (
  sessionId,
  onData,
  onError
) => {
  const sessionRef = doc(db, 'sessions', sessionId);
  const messagesRef = collection(sessionRef, 'messages');
  
  // Subscribe to session document
  const sessionUnsubscribe = onSnapshot(
    sessionRef,
    async (doc) => {
      if (doc.exists()) {
        // Get messages
        const messagesSnap = await getDocs(messagesRef);
        const messages = messagesSnap.docs.map(msgDoc => ({
          id: msgDoc.id,
          ...msgDoc.data(),
          timestamp: msgDoc.data().timestamp?.toDate()
        })).sort((a, b) => (a.timestamp?.getTime() || 0) - (b.timestamp?.getTime() || 0));

        const data = {
          id: doc.id,
          ...doc.data(),
          startedAt: doc.data().startedAt?.toDate(),
          finishedAt: doc.data().finishedAt?.toDate(),
          messages
        };
        onData(data);
      } else {
        onError(new Error('Session not found'));
      }
    },
    onError
  );

  // Subscribe to messages subcollection
  const messagesUnsubscribe = onSnapshot(
    messagesRef,
    async (snapshot) => {
      if (snapshot.metadata.hasPendingWrites) return; // Skip if local writes pending
      
      const messages = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp?.toDate()
      })).sort((a, b) => (a.timestamp?.getTime() || 0) - (b.timestamp?.getTime() || 0));

      // Get the current session data
      const sessionDoc = await getDoc(sessionRef);
      if (sessionDoc.exists()) {
        const data = {
          id: sessionDoc.id,
          ...sessionDoc.data(),
          startedAt: sessionDoc.data().startedAt?.toDate(),
          finishedAt: sessionDoc.data().finishedAt?.toDate(),
          messages
        };
        onData(data);
      }
    },
    onError
  );

  // Return cleanup function
  return () => {
    sessionUnsubscribe();
    messagesUnsubscribe();
  };
};

export const checkApiKey = async (domainName) => {
  // Attempt to fetch API key data for the email domain
  const apiKeyRef = doc(firestore, 'apiKeys', domainName);
  const apiKeySnapshot = await getDoc(apiKeyRef);

  if (apiKeySnapshot.exists()) {
    return {
      apiKeyExists: true,
      apiKey: apiKeySnapshot.data().apiKey,
    };
  } else {
    return { apiKeyExists: false };
  }
};


/**
 * Fetch simulations for the current authenticated user.
 */
export const fetchUserSimulations = async () => {
  try {
    const userUid = auth.currentUser?.uid;
    if (userUid) {
      const simulationsRef = collection(db, `accounts/${userUid}/simulations`);
      const simulationSnap = await getDocs(simulationsRef);
      return simulationSnap.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    }
    return [];
  } catch (error) {
    console.error('Error fetching simulations:', error);
    return [];
  }
};

export const saveMessageToSession = async (sessionId, message) => {
  try {
    const domain = getCurrentUserDomain();
    const sessionRef = doc(db, 'sessions', sessionId);
    
    // Verify session belongs to account
    const sessionDoc = await getDoc(sessionRef);
    if (!sessionDoc.exists() || sessionDoc.data().domain !== domain) {
      throw new Error('Unauthorized access to session');
    }

    const messagesRef = collection(sessionRef, 'messages');
    
    await addDoc(messagesRef, {
      ...message,
      timestamp: serverTimestamp()
    });
    
    return true;
  } catch (error) {
    console.error('Error saving message:', error);
    return false;
  }
};

// Get all coaches for current user
export const getCoaches = async () => {
  try {
    const account_id = await getCurrentUserAccountId();
    const coachesRef = collection(db, 'coachs');
    const q = query(coachesRef, where('account_id', '==', account_id));
    const querySnapshot = await getDocs(q);
    
    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error('Error fetching coaches:', error);
    throw error;
  }
};

interface Session {
  id: string;
  status: string;
  createdAt: any;
  coachId: string;
  finishedAt?: any;
  scoredAt?: any;
  assistantEvaluation?: {
    evaluation: {
      overall_score: number;
      highlights: string;
      opportunities: string;
      rapport_building: SkillEvaluation;
      communication_skills: SkillEvaluation;
      closing_techniques: SkillEvaluation;
      resilience: SkillEvaluation;
      active_listening: SkillEvaluation;
      objection_handling: SkillEvaluation;
    };
  };
}

interface SkillEvaluation {
  score: number;
  description: string;
}

export const getSessionsByAccount = async () => {
  try {
    const account_id = await getCurrentUserAccountId();
    const sessionsRef = collection(db, 'sessions');
    const q = query(
      sessionsRef, 
      where('account_id', '==', account_id),
      orderBy('createdAt', 'desc')
    );
    
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error('Error fetching sessions:', error);
    throw error;
  }
};

/**
 * Creates a new coach in Firestore
 * @param {Object} coachData - The coach data to save
 * @returns {Promise<string>} The ID of the created coach
 */
export const createCoach = async (coachData) => {
  try {
    const account_id = await getCurrentUserAccountId();
    const coachRef = doc(collection(db, 'coachs'));
    await setDoc(coachRef, {
      ...coachData,
      account_id,
      createdAt: serverTimestamp(),
    });
    return coachRef.id;
  } catch (error) {
    console.error('Error creating coach:', error);
    throw error;
  }
};

export const getCoachById = async (coachId) => {
  try {
    const account_id = await getCurrentUserAccountId();
    const coachRef = doc(db, 'coachs', coachId);
    const coachDoc = await getDoc(coachRef);

    if (!coachDoc.exists() || coachDoc.data().account_id !== account_id) {
      return null;
    }

    return {
      id: coachDoc.id,
      ...coachDoc.data()
    };
  } catch (error) {
    console.error('Error fetching coach:', error);
    throw error;
  }
};

export const updateCoach = async (coachId, coachData) => {
  try {
    const account_id = await getCurrentUserAccountId();
    const coachRef = doc(db, 'coachs', coachId);
    const coachDoc = await getDoc(coachRef);

    if (!coachDoc.exists() || coachDoc.data().account_id !== account_id) {
      throw new Error('Unauthorized or coach not found');
    }

    await updateDoc(coachRef, {
      ...coachData,
      updatedAt: serverTimestamp(),
    });
    return true;
  } catch (error) {
    console.error('Error updating coach:', error);
    throw error;
  }
};

export const getAccountData = async () => {
  try {
    const domain = getCurrentUserDomain();
    const accountRef = doc(db, 'accounts', domain);
    const accountDoc = await getDoc(accountRef);
    
    if (accountDoc.exists()) {
      return { id: domain, ...accountDoc.data() };
    }
    return null;
  } catch (error) {
    console.error('Error getting account data:', error);
    throw error;
  }
};

export const updateAccountData = async (accountData) => {
  try {
    const domain = getCurrentUserDomain();
    const accountRef = doc(db, 'accounts', domain);
    
    await updateDoc(accountRef, {
      ...accountData,
      updatedAt: serverTimestamp()
    });
  } catch (error) {
    console.error('Error updating account data:', error);
    throw error;
  }
};