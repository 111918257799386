import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  useToast,
  Container,
  Heading,
  Select,
  Textarea,
  useColorModeValue,
} from '@chakra-ui/react';
import { getAccountData, updateAccountData } from '../utils/firebase.utils';

interface AccountData {
  id: string;
  companyName?: string;
  productName?: string;
  problem?: string;
  solution?: string;
  competitors?: string;
  language?: string;
}

const languageOptions = [
  { value: 'english', label: 'English' },
  { value: 'spanish', label: 'Spanish' },
  { value: 'portuguese', label: 'Portuguese' },
];

const ConfigurationPage: React.FC = () => {
  const [formData, setFormData] = useState<AccountData>({
    id: '',
    companyName: '',
    productName: '',
    problem: '',
    solution: '',
    competitors: '',
    language: 'english',
  });

  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const toast = useToast();
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  useEffect(() => {
    const fetchAccountData = async () => {
      try {
        const data = await getAccountData() as AccountData;
        if (data) {
          setFormData(data);
        }
      } catch (error) {
        toast({
          title: 'Error loading account data',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchAccountData();
  }, [toast]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSaving(true);

    try {
      await updateAccountData(formData);
      toast({
        title: 'Settings updated successfully',
        status: 'success',
        duration: 3000,
      });
    } catch (error) {
      toast({
        title: 'Error saving settings',
        description: 'Please try again later',
        status: 'error',
        duration: 3000,
      });
    } finally {
      setIsSaving(false);
    }
  };

  if (isLoading) {
    return null;
  }

  return (
    <Container maxW="800px" py={8}>
      <Box
        borderWidth="1px"
        borderRadius="xl"
        p={8}
        bg={bgColor}
        borderColor={borderColor}
        shadow="lg"
      >
        <VStack spacing={6} align="stretch">
          <Heading size="lg">Account Configuration</Heading>

          <form onSubmit={handleSubmit}>
            <VStack spacing={6} align="stretch">
              <FormControl isRequired>
                <FormLabel>Company Name</FormLabel>
                <Input
                  value={formData.companyName || ''}
                  onChange={(e) => setFormData({ ...formData, companyName: e.target.value })}
                  placeholder="Enter company name"
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Default Language</FormLabel>
                <Select
                  value={formData.language}
                  onChange={(e) => setFormData({ ...formData, language: e.target.value })}
                >
                  {languageOptions.map((lang) => (
                    <option key={lang.value} value={lang.value}>
                      {lang.label}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Product Name</FormLabel>
                <Input
                  value={formData.productName || ''}
                  onChange={(e) => setFormData({ ...formData, productName: e.target.value })}
                  placeholder="Enter product name"
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Problem</FormLabel>
                <Textarea
                  value={formData.problem || ''}
                  onChange={(e) => setFormData({ ...formData, problem: e.target.value })}
                  placeholder="What problem does your product solve?"
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Solution</FormLabel>
                <Textarea
                  value={formData.solution || ''}
                  onChange={(e) => setFormData({ ...formData, solution: e.target.value })}
                  placeholder="How does your product solve this problem?"
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Competitors</FormLabel>
                <Textarea
                  value={formData.competitors || ''}
                  onChange={(e) => setFormData({ ...formData, competitors: e.target.value })}
                  placeholder="Who are your main competitors?"
                />
              </FormControl>

              <Button
                type="submit"
                colorScheme="blue"
                size="lg"
                isLoading={isSaving}
              >
                Save Changes
              </Button>
            </VStack>
          </form>
        </VStack>
      </Box>
    </Container>
  );
};

export default ConfigurationPage; 