import React from 'react';
import {
  Box,
  Heading,
  SimpleGrid,
  Text,
  VStack,
  Button,
  Badge,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaPlay, FaChartLine } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

interface TrainingPlan {
  id: string;
  title: string;
  description: string;
  difficulty: 'Beginner' | 'Intermediate' | 'Advanced';
  completedSessions: number;
  totalSessions: number;
}

const TrainingPlans: React.FC = () => {
  const navigate = useNavigate();
  const cardBg = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  // Mock data - replace with actual data from your backend
  const trainingPlans: TrainingPlan[] = [
    {
      id: '1',
      title: 'Customer Service Basics',
      description: 'Learn fundamental customer service skills and common scenarios',
      difficulty: 'Beginner',
      completedSessions: 2,
      totalSessions: 5,
    },
    {
      id: '2',
      title: 'Conflict Resolution',
      description: 'Handle difficult conversations and resolve customer complaints',
      difficulty: 'Intermediate',
      completedSessions: 0,
      totalSessions: 4,
    },
    {
      id: '3',
      title: 'Advanced Sales Techniques',
      description: 'Master complex sales scenarios and objection handling',
      difficulty: 'Advanced',
      completedSessions: 1,
      totalSessions: 6,
    },
  ];

  const getDifficultyColor = (difficulty: string) => {
    switch (difficulty) {
      case 'Beginner':
        return 'green';
      case 'Intermediate':
        return 'orange';
      case 'Advanced':
        return 'red';
      default:
        return 'gray';
    }
  };

  return (
    <Box>
      <Heading mb={6}>Training Plans</Heading>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
        {trainingPlans.map((plan) => (
          <Box
            key={plan.id}
            p={6}
            borderRadius="lg"
            bg={cardBg}
            border="1px"
            borderColor={borderColor}
            shadow="sm"
          >
            <VStack align="stretch" spacing={4}>
              <Box>
                <Badge
                  colorScheme={getDifficultyColor(plan.difficulty)}
                  mb={2}
                >
                  {plan.difficulty}
                </Badge>
                <Heading size="md" mb={2}>
                  {plan.title}
                </Heading>
                <Text color="gray.600" fontSize="sm">
                  {plan.description}
                </Text>
              </Box>

              <Text fontSize="sm">
                Progress: {plan.completedSessions}/{plan.totalSessions} sessions
              </Text>

              <SimpleGrid columns={2} spacing={3}>
                <Button
                  leftIcon={<FaPlay />}
                  colorScheme="blue"
                  size="sm"
                  onClick={() => navigate(`/roleplay/${plan.id}`)}
                >
                  Start Session
                </Button>
                <Button
                  leftIcon={<FaChartLine />}
                  variant="outline"
                  size="sm"
                  onClick={() => navigate(`/training/${plan.id}/progress`)}
                >
                  View Progress
                </Button>
              </SimpleGrid>
            </VStack>
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default TrainingPlans; 