import React, { useEffect, useState } from 'react';
import { User, getAuth } from 'firebase/auth';
import { Box, Button, Text, Input, Select, VStack, Spinner, FormLabel, Heading, useToast, Alert, AlertIcon, Checkbox, Container, SimpleGrid, Tag, HStack, Icon, Image } from '@chakra-ui/react';
import { checkCompanyData, checkApiKey, saveCompanyData } from '../utils/firebase.utils';
import { FaLightbulb, FaChartLine, FaUsers } from 'react-icons/fa';
import CoachesList from '../components/CoachesList';
import { useNavigate } from 'react-router-dom';

interface ResumeProps {
  user: User | null;
}

const Resume: React.FC<ResumeProps> = ({ user }) => {
  const [domainStatus, setDomainStatus] = useState<'checking' | 'invalid' | 'valid'>('checking');
  const [loading, setLoading] = useState(true);
  const [companyName, setCompanyName] = useState('');
  const [companyExists, setCompanyExists] = useState(false);
  const [apiKeyExists, setApiKeyExists] = useState(false);
  const toast = useToast();
  const [trainingLanguage, setTrainingLanguage] = useState('english');
  const navigate = useNavigate();
  
  useEffect(() => {
    const validateEmailDomain = async () => {
      if (user?.email) {
        const domain = user.email.split('@')[1]?.toLowerCase();
        const personalDomains = ['gmail.com', 'hotmail.com', 'yahoo.com', 'outlook.com']; 
        
        if (personalDomains.includes(domain)) {
          setDomainStatus('invalid');
        } else {
          const { companyExists, companyName, companyData } = await checkCompanyData(domain);
          setCompanyExists(companyExists);
          setCompanyName(companyName || '');
          setTrainingLanguage(companyData?.trainingLanguage || 'english');
        
          if (companyExists) {
            const apiKeyData = await checkApiKey(domain);
            setApiKeyExists(apiKeyData.apiKeyExists);
          }
          
          setDomainStatus('valid');
        }
      }
      setLoading(false);
    };

    validateEmailDomain();
  }, [user]);

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const domain = user?.email!.split('@')[1];
    const fullName = (document.getElementById('fullName') as HTMLInputElement).value;
    const selectedTeamSize = (document.getElementById('teamSize') as HTMLSelectElement).value;
    const referralSource = (document.getElementById('referralSource') as HTMLInputElement).value;
    const trainingLanguageValue = (document.getElementById('trainingLanguage') as HTMLSelectElement).value;
    setTrainingLanguage(trainingLanguageValue);
    
    const useCases = {
      coldCalling: (document.getElementById('coldCalling') as HTMLInputElement).checked,
      negotiation: (document.getElementById('negotiation') as HTMLInputElement).checked,
      hiringInterview: (document.getElementById('hiringInterview') as HTMLInputElement).checked,
      trainingPlan: (document.getElementById('trainingPlan') as HTMLInputElement).checked,
      teamOnboarding: (document.getElementById('teamOnboarding') as HTMLInputElement).checked,
    };

    try {
      await saveCompanyData(domain, { 
        fullName, 
        companyName, 
        teamSize: selectedTeamSize, 
        referralSource,
        trainingLanguage,
        useCases
      });
      toast({ title: "Data saved successfully", status: "success", duration: 3000, isClosable: true });

      // Update the local state to trigger re-render
      setCompanyExists(true);

      // Recheck for apiKey after saving
      const apiKeyData = await checkApiKey(domain);
      setApiKeyExists(apiKeyData.apiKeyExists);
    } catch (error) {
      toast({ title: "Error saving data", description: (error as Error).message, status: "error", duration: 3000, isClosable: true });
    }
  };

  if (domainStatus === 'checking' || loading) {
    return (
      <Box textAlign="center" mt={10}>
        <Spinner size="xl" />
        <Text mt={3}>Loading your data...</Text>
      </Box>
    );
  }

  if (domainStatus === 'invalid') {
    return (
      <Box textAlign="center" mt={10} mx="auto" maxW="lg" bg="gray.50" p={6} >
        <Alert status="error" borderRadius="md" mb={4}>
          <AlertIcon />
          <Text fontSize="lg" color="red.700">You cannot register using a personal email. Please use your company email.</Text>
        </Alert>
        <Button 
          onClick={() => {
            getAuth().signOut();
            navigate('/login');
          }} 
          colorScheme="teal" 
          mt={4}
        >
          Back to Login
        </Button>
      </Box>
    );
  }

  if (!companyExists) {
    return (
      <Box w="full" bg="white">
        <Container maxW="container.xl" py={10}>
          <SimpleGrid columns={[1, null, 2]} spacing={10}>
            {/* Left side - Title, Features, and Image */}
            <Box textAlign="left" py={10} px={5}>
              <Tag>Welcome to ObjectionGuru</Tag>
              <Heading as="h1" size="2xl" mb={4} color="teal.800" lineHeight="shorter">
                Let's Set Up Your AI Sales Coach
              </Heading>
              <Text mb={6} fontSize="lg" color="gray.600">
                Get ready to transform your sales training with personalized AI-driven scenarios.
              </Text>
              
              {/* Core Features */}
              <VStack spacing={4} align="start" mt={8} mb={8}>
                <HStack>
                  <Icon as={FaLightbulb} w={6} h={6} color="blue.500" />
                  <Text fontSize="md">Personalized training scenarios</Text>
                </HStack>
                <HStack>
                  <Icon as={FaChartLine} w={6} h={6} color="blue.500" />
                  <Text fontSize="md">Real-time performance analytics</Text>
                </HStack>
                <HStack>
                  <Icon as={FaUsers} w={6} h={6} color="blue.500" />
                  <Text fontSize="md">Team-wide progress tracking</Text>
                </HStack>
              </VStack>

              {/* Image Section */}
              <Box mt={6} position="relative">
                <Box
                  borderRadius="lg"
                  overflow="hidden"
                  boxShadow="xl"
                  bg="gray.100"
                  position="relative"
                >
                  <Image
                    src="./score-call.png"
                    alt="AI Sales Coach Demo"
                    width="100%"
                    height="auto"
                    objectFit="cover"
                  />
                </Box>
                {/* Optional: Add a floating badge or overlay */}
                <Box
                  position="absolute"
                  top={4}
                  right={4}
                  bg="blue.500"
                  color="white"
                  px={3}
                  py={1}
                  borderRadius="full"
                  fontSize="sm"
                  fontWeight="bold"
                >
                  AI-Powered
                </Box>
              </Box>
            </Box>

            {/* Right side - Form */}
            <Box bg="gray.50" p={8} borderRadius="lg" boxShadow="lg">
              <VStack spacing={6} as="form" onSubmit={handleFormSubmit}>
                <FormLabel htmlFor="fullName">Full Name</FormLabel>
                <Input 
                  id="fullName" 
                  placeholder="Full Name" 
                  defaultValue={user?.displayName || ''} 
                  bg="white"
                />
                
                <FormLabel htmlFor="companyName">Company Name</FormLabel>
                <Input 
                  id="companyName" 
                  placeholder="Company Name" 
                  value={companyName} 
                  onChange={(e) => setCompanyName(e.target.value)} 
                  bg="white"
                />
                
                <FormLabel htmlFor="trainingLanguage">Preferred Training Language</FormLabel>
                <Select id="trainingLanguage" placeholder="Select language" bg="white">
                  <option value="english">English</option>
                  <option value="spanish">Spanish</option>
                  <option value="portuguese">Portuguese</option>
                  <option value="other">Other</option>
                </Select>

                <FormLabel>Use Cases</FormLabel>
                <SimpleGrid columns={2} spacing={2} width="100%">
                  <Checkbox id="coldCalling">Cold Calling</Checkbox>
                  <Checkbox id="negotiation">Negotiation</Checkbox>
                  <Checkbox id="hiringInterview">Hiring Interview</Checkbox>
                  <Checkbox id="trainingPlan">Training Plan</Checkbox>
                  <Checkbox id="teamOnboarding">Team Onboarding</Checkbox>
                </SimpleGrid>

                <FormLabel htmlFor="teamSize">Team Size</FormLabel>
                <Select id="teamSize" placeholder="Select your team size" bg="white">
                  <option value="1-5">1-5</option>
                  <option value="5-10">5-10</option>
                  <option value="10-30">10-30</option>
                  <option value="30-80">30-80</option>
                  <option value="81+">81+</option>
                </Select>

                <FormLabel htmlFor="referralSource">How did you hear about us?</FormLabel>
                <Input id="referralSource" placeholder="Mention source" bg="white" />
                
                <Button 
                  colorScheme="teal" 
                  type="submit" 
                  size="lg" 
                  width="100%"
                  bg="#2c3192"
                  _hover={{ bg: '#24276b' }}
                >
                  Get Started
                </Button>
              </VStack>
            </Box>
          </SimpleGrid>
        </Container>
      </Box>
    );
  }

  if (!apiKeyExists) {
    return (
      <Box textAlign="center" mt={10} mx="auto" maxW="lg" bg="gray.50" p={6} borderRadius="md" boxShadow="md">
        <Alert status="info" borderRadius="md" mb={4}>
          <AlertIcon />
          <Heading size="md" mb={2}>Account Setup in Progress</Heading>
          <Text color="gray.700">We are currently setting up your account with your company's information. Your customized training agents are being generated. We will contact you as soon as it's ready for testing.</Text>
        </Alert>
      </Box>
    );
  }

  return (
    <Container maxW="container.xl" py={8}>
      <Heading mb={6}>Available AI Role Play Coaches</Heading>
      <CoachesList language={trainingLanguage} />
    </Container>
  );
};

export default Resume;